import * as React from 'react';
import './Connector.css';
import connectors from './connectors.json';
import ContactUsFooter from '../ContactUsFooter';
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Navigation, Pagination } from 'swiper'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import { SuperSEO } from 'react-super-seo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'


interface ConnectorProps {
    id: string;
}

const Connector: React.FunctionComponent<ConnectorProps> = (props: React.PropsWithChildren<ConnectorProps>) => {
    const connector = connectors.find(x => x.id == props.id);

    return (
        <div>
            <section className="position-relative bg-gradient-blur mb-5 pt-5">
                <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12 col-lg-6 me-xl-auto z-index-1">
                            <div className="row align-items-center display-6 mb-5">
                                <div className="col-8 offset-2">
                                    <img src={connector?.icon} alt="{connector?.name}" className="position-relative img-fluid me-3 connector-image" />
                                    {connector?.name} Connector
                                </div>
                            </div>
                            <div className="row align-items-center display-6 connector-property">
                                <div className="col-8 offset-2 connector-property-title">
                                    Type
                                </div>
                                <div className="col-8 offset-2 connector-property-data">
                                    {connector?.type}
                                </div>
                            </div>
                            <div className="row align-items-center display-6 connector-property">
                                <div className="col-8 offset-2 connector-property-title">
                                    Supports
                                </div>
                                <div className="col-8 offset-2 connector-property-data">
                                    {connector?.types ?? "N/A"}
                                </div>
                            </div>
                            <div className="row align-items-center display-6 connector-property">
                                <div className="col-8 offset-2 connector-property-title">
                                    Capabilities
                                </div>
                                <div className="col-8 offset-2">
                                    <table className="table table-header-rotated matrix mt-2 w-100">
                                        <thead className="matrix-heading">
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th><div><span>Layout</span></div></th>
                                                <th><div><span>Style</span></div></th>
                                                <th><div><span>Elements</span></div></th>
                                                <th><div><span>Schema</span></div></th>
                                                <th><div><span>Rules</span></div></th>
                                                <th><div><span>Images</span></div></th>
                                                <th><div><span>Data</span></div></th>
                                            </tr>
                                        </thead>
                                        <tbody className="matrix-body">
                                            <tr>
                                                <th>Reads</th>
                                                <td><FontAwesomeIcon icon={connector?.reads?.find(x => x == 'Layout') ? faCheck : faXmark} className={connector?.reads?.find(x => x == 'Layout') ? 'text-success' : 'text-danger'} /></td>
                                                <td><FontAwesomeIcon icon={connector?.reads?.find(x => x == 'Style') ? faCheck : faXmark} className={connector?.reads?.find(x => x == 'Style') ? 'text-success' : 'text-danger'} /></td>
                                                <td><FontAwesomeIcon icon={connector?.reads?.find(x => x == 'Elements') ? faCheck : faXmark} className={connector?.reads?.find(x => x == 'Elements') ? 'text-success' : 'text-danger'} /></td>
                                                <td><FontAwesomeIcon icon={connector?.reads?.find(x => x == 'Schema') ? faCheck : faXmark} className={connector?.reads?.find(x => x == 'Schema') ? 'text-success' : 'text-danger'} /></td>
                                                <td><FontAwesomeIcon icon={connector?.reads?.find(x => x == 'Rules') ? faCheck : faXmark} className={connector?.reads?.find(x => x == 'Rules') ? 'text-success' : 'text-danger'} /></td>
                                                <td><FontAwesomeIcon icon={connector?.reads?.find(x => x == 'Images') ? faCheck : faXmark} className={connector?.reads?.find(x => x == 'Images') ? 'text-success' : 'text-danger'} /></td>
                                                <td><FontAwesomeIcon icon={connector?.reads?.find(x => x == 'Data') ? faCheck : faXmark} className={connector?.reads?.find(x => x == 'Data') ? 'text-success' : 'text-danger'} /></td>
                                            </tr>
                                            <tr>
                                                <th>Writes</th>
                                                <td><FontAwesomeIcon icon={connector?.writes?.find(x => x == 'Layout') ? faCheck : faXmark} className={connector?.writes?.find(x => x == 'Layout') ? 'text-success' : 'text-danger'} /></td>
                                                <td><FontAwesomeIcon icon={connector?.writes?.find(x => x == 'Style') ? faCheck : faXmark} className={connector?.writes?.find(x => x == 'Style') ? 'text-success' : 'text-danger'} /></td>
                                                <td><FontAwesomeIcon icon={connector?.writes?.find(x => x == 'Elements') ? faCheck : faXmark} className={connector?.writes?.find(x => x == 'Elements') ? 'text-success' : 'text-danger'} /></td>
                                                <td><FontAwesomeIcon icon={connector?.writes?.find(x => x == 'Schema') ? faCheck : faXmark} className={connector?.writes?.find(x => x == 'Schema') ? 'text-success' : 'text-danger'} /></td>
                                                <td><FontAwesomeIcon icon={connector?.writes?.find(x => x == 'Rules') ? faCheck : faXmark} className={connector?.writes?.find(x => x == 'Rules') ? 'text-success' : 'text-danger'} /></td>
                                                <td><FontAwesomeIcon icon={connector?.writes?.find(x => x == 'Images') ? faCheck : faXmark} className={connector?.writes?.find(x => x == 'Images') ? 'text-success' : 'text-danger'} /></td>
                                                <td><FontAwesomeIcon icon={connector?.writes?.find(x => x == 'Data') ? faCheck : faXmark} className={connector?.writes?.find(x => x == 'Data') ? 'text-success' : 'text-danger'} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-6 text-center">
                            <div className="position-relative">
                                <img src={connector?.hero} alt="{connector?.name}" className="w-100" />
                            </div>
                        </div>
                    </div>
                    {props.children}
                </div>
            </section>
            <section className="position-relative mb-5 bg-gradient-blur pt-3">
                <div className="container-fluid px-xl-9 position-relative z-index-1">
                    <div className="row mb-5">
                        <div className="col-lg-8 mx-auto text-center col-xl-7">
                            <h2 className="display-4">
                                See it in action
                            </h2>
                        </div>
                    </div>
                    <div className="container-fluid px-xl-9 position-relative z-index-1 my-5">
                        <div className="row align-items-center col-12 col-lg-6 offset-lg-3">
                            <Swiper spaceBetween={100} modules={[Navigation, Pagination, EffectCoverflow]} effect="coverflow" speed={750} navigation={true} className="px-5"
                                onSlideChange={(swiperCore) => {
                                    var player = swiperCore.slides[swiperCore.previousIndex].querySelector('iframe');
                                    player?.contentWindow?.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                                }}>
                                {connector?.videos.map(function (video, index) {
                                    return <SwiperSlide key={index.toString()}>
                                        <div className="ratio ratio-16x9">
                                            <iframe width="560" height="315" src={video} title="YouTube video player"
                                                frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        </div>
                                    </SwiperSlide>;
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <ConnectorFooter />
            <ContactUsFooter />

            <SuperSEO
                title={`${connector?.name} Connector - Kudzu`}
                description={`${connector?.name} connector for Kudzu`}
                lang="en"
                googleSiteVerification="1hS9bBPIOWWfEIJ33ELv_Z75P9JK2Idlcc-TFCB8_lU"
                openGraph={{
                    ogImage: {
                        ogImage: `https://${location.host}/${connector?.hero}`,
                        ogImageAlt: connector?.name,
                        ogImageType: connector?.hero.indexOf('.png') ? 'image/png' : 'image/jpeg'
                    },
                    ogType: 'website',
                    ogLocale: 'en-US',
                    ogTitle: `${connector?.name} Connector - Kudzu`,
                    ogSiteName: 'Kudzu',
                    ogUrl: location.href
                }}
                twitter={{
                    twitterSummaryCardWithLargeImage: {
                        summaryCardTitle: `${connector?.name} Connector - Kudzu`,
                        summaryCardImage: `https://${location.host}/${connector?.hero}`,
                        summaryCardImageAlt: connector?.name,
                        summaryCardSiteUsername: 'kudzusoftware'
                    },
                }}
            />

        </div>
    );
}

const ConnectorMoreDetails: React.FunctionComponent<any> = (props: React.PropsWithChildren<any>) => {
    return (
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                {props.children}
            </div>
        </section>
    );
}

class ConnectorFooter extends React.PureComponent<{}, {}> {

    public render() {

        return (
            <section className="position-relative bg-gradient-blur mb-5 pt-5">
                <div className="container-fluid px-xl-9 position-relative z-index-1 my-5">
                    <div className="row align-items-center mx-5">

                        <div className="col-12 col-sm-12 col-lg-6">
                            <div className="row">
                                {connectors.map(function (connector, index) {
                                    return <div className="col-6 col-md-4 col-xl-3" key={index.toString()}>
                                        <div className="connector-footer-pill">
                                            <img src={connector?.icon} alt="" className="img-fluid position-relative" />
                                            <div>
                                                <span>{connector?.name}</span>
                                                <a href={`/connectors/${connector?.id}`} className="btn btn-sm btn-primary">More</a>
                                            </div>
                                        </div>
                                    </div>;
                                })}
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                            <h1 className="display-4 mb-4">
                                <span>Kudzu Connectors</span>
                            </h1>
                            <div className="position-relative lead mb-5">
                                <span>Kudzu supports a wide range of readers and writers with more delivered in each new release. Check them out to learn more about their capabilities and see them in action.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export { Connector, ConnectorMoreDetails, ConnectorFooter };


