import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ContactUsFooter from '../ContactUsFooter'
import videos from './videos.json';
import './Videos.css';

const Videos = () => {

    return (
        <div>
            <section className="position-relative bg-gradient-blur mb-5 pt-5">
                <h1 className="display-3 mb-5 ms-5">
                    <span>Video Gallery</span>
                </h1>
                <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                    <div className="row mx-5 align-items-center">
                        {videos.map(function (video, index) {
                            return <div className="col-12 col-lg-3 position-relative text-center mb-4" key={index.toString()}>
                                <iframe src={video.url} title={video.title}
                                    frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                <p><small className="text-muted">{video.title}</small></p>
                            </div>
                        })}

                        <div className="col-12 col-lg-3 position-relative text-center lead">
                            More videos on the Kudzu<br />
                            <a href="https://www.youtube.com/channel/UC98WiJ9iSeUA2mfqzyGR0bg" target="_blank">
                                <img src="/images/brands/Youtube.svg" className="youtube" />
                            </a>
                            <br />Channel
                        </div>
                    </div>
                </div>
            </section>
            <ContactUsFooter />
        </div>
    );
}

export default connect()(Videos);
