import * as React from 'react';
import { connect } from 'react-redux';
import { SuperSEO } from 'react-super-seo';
import ContactUsFooter from '../ContactUsFooter'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Navigation, Pagination } from 'swiper'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

const Accessibility = () => (
    <div>
        <SuperSEO
            title="Accessibility (WCAG 2.1 and Section 508 Compliance) - Kudzu"
            description="Accessibility does not have to be driven by Section 508 and WCAG compliance. Find accessibility resources at the A11Y Project."
            lang="en"
            googleSiteVerification="1hS9bBPIOWWfEIJ33ELv_Z75P9JK2Idlcc-TFCB8_lU"
            openGraph={{
                ogImage: {
                    ogImage: `https://${location.host}/images/kudzu-hero.png`,
                    ogImageAlt: 'Accessibility (WCAG 2.1 and Section 508 Compliance) - Kudzu',
                    ogImageType: 'image/png'
                },
                ogType: 'website',
                ogLocale: 'en-US',
                ogTitle: 'Accessibility (WCAG 2.1 and Section 508 Compliance) - Kudzu',
                ogSiteName: 'Kudzu',
                ogUrl: location.href
            }}
            twitter={{
                twitterSummaryCardWithLargeImage: {
                    summaryCardTitle: 'Accessibility (WCAG 2.1 and Section 508 Compliance) - Kudzu',
                    summaryCardImage: `https://${location.host}/images/kudzu-hero.png`,
                    summaryCardImageAlt: 'Accessibility (WCAG 2.1 and Section 508 Compliance) - Kudzu',
                    summaryCardSiteUsername: 'kudzusoftware'
                }
            }}
        />
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-4 offset-1 z-index-1">
                        <h1 className="display-3 mb-4">
                            <span>Be <b>Accessible</b></span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <p>Accessibility does not have to be
                                <span> </span>
                                <span className="position-relative d-inline-block">compliance-driven
                                    <svg className="position-absolute start-0 bottom-0 text-warning z-index-n1" preserveAspectRatio="none"
                                        width="100%" height="8" viewBox="0 0 63 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.119995 2.79013C0.119995 2.79013 26.91 -0.589865 62.82 1.93013" stroke="#14A76A"
                                            strokeWidth="2" />
                                    </svg>
                                </span>
                                <span>!</span>
                            </p>
                            <p>
                                <span>Kudzu can help your organization create </span>
                                <span className="position-relative d-inline-block">accessible
                                    <svg className="position-absolute start-0 bottom-0 text-warning z-index-n1" preserveAspectRatio="none"
                                        width="100%" height="8" viewBox="0 0 63 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.119995 2.79013C0.119995 2.79013 26.91 -0.589865 62.82 1.93013" stroke="#14A76A"
                                            strokeWidth="2" />
                                    </svg>
                                </span>
                                <span> user experiences for your employees, partners, and customers as a </span>
                                <span className="position-relative d-inline-block">feature
                                    <svg className="position-absolute start-0 bottom-0 text-warning z-index-n1" preserveAspectRatio="none"
                                        width="100%" height="8" viewBox="0 0 63 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.119995 2.79013C0.119995 2.79013 26.91 -0.589865 62.82 1.93013" stroke="#14A76A"
                                            strokeWidth="2" />
                                    </svg>
                                </span>
                                <span> of your digital transformation journey.</span>
                            </p>
                            {/*<p>*/}
                            {/*    A comprehensive list of accessibility resources can be found at the <a href="https://www.a11yproject.com/" target="_blank">A11Y Project</a>.*/}
                            {/*</p>*/}
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                        <div className="position-relative">
                            <img src="images/accessibility.png" alt="" className="w-100" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/*<section className="position-relative mb-5 bg-gradient-blur pt-3">*/}
        {/*    <div className="container-fluid px-xl-9 position-relative z-index-1">*/}
        {/*        <div className="row mb-5">*/}
        {/*            <div className="col-lg-10 mx-auto text-center col-xl-9">*/}
        {/*                <h2 className="display-4">*/}
        {/*                    Section 508 and WCAG 2.1 Compliance*/}
        {/*                </h2>*/}
        {/*            </div>*/}
        {/*            <div className="col-lg-10 mx-auto col-xl-9">*/}
        {/*                <div className="position-relative lead mb-5">*/}
        {/*                    <p>Kudzu ensures forms are compliant with the latest accessibility standards by applying required and, in many cases, recommended metadata to labels and inputs as they are published. Metadata associated with a form element's role, relationships, and labeling are generated during read and recognition operations. Kudzu also detects contrast issues, makes recommendations to address them, and surfaces those recommendations in the Uniform Editor where they can be acted upon.</p>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</section>*/}

        <section className="position-relative bg-gradient-blur mb-5 pt-2 px-5">
            <div className="container-fluid px-xl-9 position-relative py-9 py-lg-11">
                <div className="row my-5">
                    <div className="col-lg-8 mx-auto text-center col-xl-7">
                        <h2 className="mb-6 mb-lg-9 display-5" data-aos="fade-down" data-aos-delay="100">
                            How we help you do it
                        </h2>
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                        <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                            {/*<FontAwesomeIcon icon={faFileImport} size="8x" className="mb-4 me-4 feature-icon" />*/}
                            <div className="feature-details">
                                <h5>Compliance</h5>
                                <p className="mb-0">
                                    Kudzu can help your organization meet WCAG 2.1 compliance goals by applying required and, in many cases, recommended metadata to labels and inputs as they are written/rendered.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                        <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                            {/*<FontAwesomeIcon icon={faEye} size="8x" className="mb-4 me-4 feature-icon" />*/}
                            <div className="feature-details">
                                <h5>PDF re-writing</h5>
                                <p className="mb-0">
                                    Convert static, untagged PDFs to WCAG 2.1 compliant fillable PDFs in a few clicks.  Kudzu can also apply missing accessibility attributes to existing tagged PDFs.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                        <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                            {/*<FontAwesomeIcon icon={faPenToSquare} size="8x" className="mb-4 me-4 feature-icon" />*/}
                            <div className="feature-details">
                                <h5>Connections</h5>
                                <p className="mb-0">
                                    Kudzu Readers start the process by gathering metadata about each element on a form.  The Recognition Service takes over and shapes that metadata into accessibility properties.  Then Kudzu Writers promote those accessibility properties to platform-native capabilities.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                        <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                            {/*<FontAwesomeIcon icon={faDatabase} size="8x" className="mb-4 me-4 feature-icon" />*/}
                            <div className="feature-details">
                                <h5>Readability</h5>
                                <p className="mb-0">
                                    Kudzu's patented recognition engine takes information it gathers on form elements, including relationships to other form elements, and builds labels that mean something.  It can even handle complex labeling scenarios where multiple related elements (e.g. compound column and row headers in a table) contribute to an input's label.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                        <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                            {/*<FontAwesomeIcon icon={faCopy} size="8x" className="mb-4 me-4 feature-icon" />*/}
                            <div className="feature-details">
                                <h5>Contrast</h5>
                                <p className="mb-0">
                                    Kudzu detects when a form element's contrast ratio falls below acceptable thresholds, makes recommendations to address them, and surfaces those recommendations in the Uniform Editor where they can be acted upon.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                        <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                            {/*<FontAwesomeIcon icon={faFont} size="8x" className="mb-4 me-4 feature-icon" />*/}
                            <div className="feature-details">
                                <h5>Above and beyond</h5>
                                <p className="mb-0">
                                    Not every modern platform fully supports accessibility natively. In those instances, Kudzu adds an accessibility layer on top of the form's native payload. An example of this can be found in the K2 SmartForm connector.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="position-relative mb-5 bg-gradient-blur pt-3">
            <div className="container-fluid px-xl-9 position-relative z-index-1">
                <div className="row mb-5">
                    <div className="col-lg-8 mx-auto text-center col-xl-7">
                        <h2 className="display-4">
                            See it in action
                        </h2>
                    </div>
                </div>
                <div className="container-fluid px-xl-9 position-relative z-index-1 my-5">
                    <div className="row align-items-center col-12 col-lg-6 offset-lg-3">
                        <Swiper spaceBetween={100} modules={[Navigation, Pagination, EffectCoverflow]} effect="coverflow" speed={750} navigation={true} className="px-5"
                            onSlideChange={(swiperCore) => {
                                var player = swiperCore.slides[swiperCore.previousIndex].querySelector('iframe');
                                player?.contentWindow?.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                            }}>
                            <SwiperSlide>
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Zt7cu0A5zCE?enablejsapi=1" title="YouTube video player"
                                        frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
        <ContactUsFooter />
    </div>
);

export default connect()(Accessibility);
