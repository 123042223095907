import * as React from 'react';
import { connect } from 'react-redux';
import { SuperSEO } from 'react-super-seo';
import ContactUsFooter from '../ContactUsFooter'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Navigation, Pagination } from 'swiper'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

const RecognitionServices = () => (
    <div>
        <SuperSEO
            title="Recognition Services - Kudzu"
            lang="en"
            googleSiteVerification="1hS9bBPIOWWfEIJ33ELv_Z75P9JK2Idlcc-TFCB8_lU"
            openGraph={{
                ogImage: {
                    ogImage: `https://${location.host}/images/kudzu-hero.png`,
                    ogImageAlt: 'Recognition Services - Kudzu',
                    ogImageType: 'image/png'
                },
                ogType: 'website',
                ogLocale: 'en-US',
                ogTitle: 'Recognition Services - Kudzu',
                ogSiteName: 'Kudzu',
                ogUrl: location.href
            }}
            twitter={{
                twitterSummaryCardWithLargeImage: {
                    summaryCardTitle: 'Recognition Services - Kudzu',
                    summaryCardImage: `https://${location.host}/images/kudzu-hero.png`,
                    summaryCardImageAlt: 'Recognition Services - Kudzu',
                    summaryCardSiteUsername: 'kudzusoftware'
                }
            }}
        />
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-3 mb-4">
                            <span>Recognition Services</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <p>Kudzu's patented Recognition Service takes unstructured shape and content output from the reader and uses it to create a structured Uniform model. Shapes are analyzed in the context of other shapes and adjacent textual content to determine if they are ornamentation, indicative of an input, and/or can be combined with adjacent shapes to form a table.</p>
                            <p>The Recognition Service can detect complex tables with compound column and row headers.  It is also capable of detecting repeating tables.</p>
                            <p>This core service of the Kudzu platform is architected as a provider layer, meaning it can be swapped out or augmented with form recognition and cognitive service offerings from 3rd parties.</p>                            
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                        <img src="images/recognition-hero.png" alt="" className="img-fluid" />
                    </div>
                </div>                
            </div>
        </section>
        <section className="position-relative mb-5 bg-gradient-blur pt-3">
            <div className="container-fluid px-xl-9 position-relative z-index-1">
                <div className="row mb-5">
                    <div className="col-lg-8 mx-auto text-center col-xl-7">
                        <h2 className="display-4">
                            See it in action
                        </h2>
                    </div>
                </div>
                <div className="container-fluid px-xl-9 position-relative z-index-1 my-5">
                    <div className="row align-items-center col-12 col-lg-6 offset-lg-3">
                        <Swiper spaceBetween={100} modules={[Navigation, Pagination, EffectCoverflow]} effect="coverflow" speed={750} navigation={true} className="px-5"
                            onSlideChange={(swiperCore) => {
                                var player = swiperCore.slides[swiperCore.previousIndex].querySelector('iframe');
                                player?.contentWindow?.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                            }}>
                            <SwiperSlide>
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/JavE2HFbC9w" title="YouTube video player"
                                        frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
        <ContactUsFooter />
    </div>
);

export default connect()(RecognitionServices);
