import * as React from 'react';
import { connect } from 'react-redux';
import { SuperSEO } from 'react-super-seo';
import ContactUsFooter from '../ContactUsFooter'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectCoverflow, Autoplay, Navigation, Pagination, EffectFade } from 'swiper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

const InfoPathReplacement = () => (
    <div>
        <SuperSEO
            title="Automated InfoPath Migration Consultancy - Kudzu"
            lang="en"
            googleSiteVerification="1hS9bBPIOWWfEIJ33ELv_Z75P9JK2Idlcc-TFCB8_lU"
            openGraph={{
                ogImage: {
                    ogImage: `https://${location.host}/images/kudzu-hero.png`,
                    ogImageAlt: 'Automated InfoPath Migration Consultancy - Kudzu',
                    ogImageType: 'image/png'
                },
                ogType: 'website',
                ogLocale: 'en-US',
                ogTitle: 'Automated InfoPath Migration Consultancy - Kudzu',
                ogSiteName: 'Kudzu',
                ogUrl: location.href
            }}
            twitter={{
                twitterSummaryCardWithLargeImage: {
                    summaryCardTitle: 'Automated InfoPath Migration Consultancy - Kudzu',
                    summaryCardImage: `https://${location.host}/images/kudzu-hero.png`,
                    summaryCardImageAlt: 'Automated InfoPath Migration Consultancy - Kudzu',
                    summaryCardSiteUsername: 'kudzusoftware'
                }
            }}
        />
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-6 offset-1 z-index-1">
                        <h1 className="display-3 mb-4">
                            <span>Automated InfoPath Migration Consultancy</span>
                        </h1>
                        <div className="position-relative mb-5">
                            <div className="lead">
                                <p>Kudzu's automation-assisted conversion tool can quickly replace your existing InfoPath forms with a growing number of low and no code technologies like Microsoft Power Apps, Nintex Automation Cloud, Jigx, and many more.  Most forms are converted in under a minute with just a few clicks.</p><p>But when it comes down to starting this type of project, it can sometimes feel daunting.  How many InfoPath forms do we have?  Where are all the forms located?  How many times have all of our forms been filled out?  When was the last time some of these forms were actually used?  In addition to these questions, another, often over looked question is: Is this form even a good candidate for conversion to Platform X or conversion at all?</p><p>The Kudzu Automated InfoPath Migration Consultancy helps you answer these questions.</p>
                            </div>                            
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-4">
                        <div className="position-relative">
                            <img src="images/infopath-replacement-hero.png" alt="" className="w-100 ms-5" />
                        </div>
                    </div>
                  
                </div>
            </div>
        </section>

        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center mt-5">
                    <div className="col-12 col-sm-12 col-lg-6">
                        <div className="position-relative">
                            <Swiper spaceBetween={50} autoplay={{ delay: 4000 }} modules={[Autoplay, EffectFade]} effect="fade" speed={750}>
                                <SwiperSlide>
                                    <img src="images/formulator/scan.png" alt="" className="w-100" />
                                    <div className="slide-title">Point the FORMulator at your on-prem or SharePoint online instances</div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src="images/formulator/report.png" alt="" className="w-100" />
                                    <div className="slide-title">Review the scan results</div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src="images/formulator/roi.png" alt="" className="w-100" />
                                    <div className="slide-title">Use the ROI calculator to estimate conversion effort with and without Kudzu</div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src="images/formulator/share.png" alt="" className="w-100" />
                                    <div className="slide-title">Export the summary, details, and ROI calculator to Excel</div>
                                </SwiperSlide>
                            </Swiper>     
                            {/*<img src="images/connectors/infopath-formulator.png" alt="" className="w-100 shadow" style={{ border: '1px solid black' }} />*/}
                        </div>
                    </div>

                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-5 mb-4">
                            <span>How does it work?</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <p>We start with the Kudzu FORMulator.  From here, we'll have a very good understanding of how big your InfoPath problem really is (or isn't if you're lucky).  Using this information, the team at Kudzu will work with you and your team to dive deeper into the analysis and help you gain more insight into how you'll begin tackling the migrations.  Essentially, it can help you scope your project.</p>
                            <p>After the FORMulator output has been reviewed, it's time to run some of the InfoPath forms through Kudzu.  During this time, the team at Kudzu will walk you through the following topics within the Kudzu User Interface:</p>
                            <ol>
                                <li>The home page</li>
                                <li>Form import wizards</li>
                                <li>Differences between connector options</li>
                                <li>Setting up connections to your target environments</li>
                                <li>Advanced options</li>
                                <li>The Kudzu Uniform Editor</li>
                                <li>The Kudzu Re-imaginer</li>
                                <li>Data migration</li>
                            </ol>
                            <p>Together, we will convert some of your forms to your target platform.  This will not only provide you with knowledge on how to use the tool but will also provide insight into how well the form translates into the target platform.  Which leads us to the next question&#46;&#46;&#46;</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-4 offset-1 z-index-1">
                        <h1 className="display-5 mb-4">
                            <span>Will all my forms convert nicely to Platform X?</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <p>Many customers go into their InfoPath Migration project with an expectation that their preferred target platform can just do everything that InfoPath can and that things will &quot;just work&quot;.  Unfortunately, that's not always the case &#45; all platforms are not created equally.  The team at Kudzu will dive deeper into this question and will help you make an informed decision on what platform the converted InfoPath form should (or shouldn't) be published to by covering the following topics:</p>
                            <ol>
                                <li>Can Platform X handle repeating sections?</li>
                                <li>How are rules implemented in Platform X?</li>
                                <li>What &quot;last mile&quot; work will need to be done within Platform X once the form has been migrated?</li>
                                <li>Compare and contrast Platform X with Platform Y.</li>
                                <li>What platforms are better for more complex forms, and what platforms are better for more basic forms?</li>
                                <li>What are my options around mobile?</li>
                            </ol>
                            <p>In the end, you will have a greater understanding of the decision making process when determining what target platform a given InfoPath should be converted to.</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                        <div className="position-relative">
                            <img src="images/infopath-replacement.png" alt="" className="w-100" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center mt-5">
                    <div className="col-12 col-sm-12 col-lg-6">
                        <div className="position-relative">
                            <img src="images/container-scaffolding.png" alt="" className="w-100" />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-5 mb-4">
                            <span>How many forms will we analyze, and what happens next?</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <p>The number of forms that we will be able to migrate during the engagement depends on many factors including the complexity of each form, the number of desired target platforms forms are converted to, etc.  But regardless of that number, at the end of the engagement, not only will you have valuable insight into your migration project's scope, but you'll also be fully capable of using the tool and making informed decisions on which platform each form should be converted to.</p>
                            <p>The size of your migration project is largely dictated by two factors:</p>
                            <ol>
                                <li>The number of forms that require conversion.</li>
                                <li>The average complexity of each form.</li>
                            </ol>
                            <p>Every customer's migration project is different &#45; some have a small scope while others have very, very large scopes.  In either case, Kudzu has a very diverse and global network of partners who can assist you, if needed, in executing your migration.</p>
                        </div>
                    </div>
                    {/*<div className="col-12 text-center"><a href="https://outlook.office365.com/owa/calendar/KudzuPlatformIntroduction@kudzusoftware.com/bookings/" className="btn btn-lg btn-primary">Book an appointment with one of our InfoPath Replacement Experts to get started or answer any questions</a></div>*/}
                </div>
            </div>
        </section>

        <section className="position-relative mb-5 bg-gradient-blur pt-3">
            <div className="container-fluid px-xl-9 position-relative z-index-1">
                <div className="row mb-5">
                    <div className="col-lg-8 mx-auto text-center col-xl-7">
                        <h2 className="display-4">
                            See it in action
                        </h2>
                    </div>
                </div>
                <div className="container-fluid px-xl-9 position-relative z-index-1 my-5">
                    <div className="row align-items-center col-12 col-lg-6 offset-lg-3">
                        <Swiper spaceBetween={100} modules={[Navigation, Pagination, EffectCoverflow]} effect="coverflow" speed={750} navigation={true} className="px-5"
                            onSlideChange={(swiperCore) => {
                                var player = swiperCore.slides[swiperCore.previousIndex].querySelector('iframe');
                                player?.contentWindow?.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                            }}>
                            <SwiperSlide>
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/eZ47gDE-shg?enablejsapi=1" title="YouTube video player"
                                        frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/QL3XI61aWvM?enablejsapi=1" title="YouTube video player"
                                        frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/S2XDVNjqEzI?enablejsapi=1" title="YouTube video player"
                                        frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/QPcnbpEhLo4?enablejsapi=1" title="YouTube video player"
                                        frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ThawtcVbVIw?enablejsapi=1" title="YouTube video player"
                                        frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
        <ContactUsFooter />
    </div>
);

export default connect()(InfoPathReplacement);
