import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Home from './components/Home';
import NotFound from './components/NotFound';
import ContactUs from './components/ContactUs';
import Desktop from './components/product/Desktop';
import Uniform from './components/product/Uniform';
import RecognitionServices from './components/product/RecognitionServices';
import GenerativeAI from './components/product/GenerativeAI';
import ConnectorFramework from './components/product/ConnectorFramework';
import InfoPathReplacement from './components/solution/InfoPathReplacement';
import InfoPathReplacementRedirect from './components/redirects/InfoPathReplacement';
import InfoPathConsultancy from './components/solution/InfoPathConsultancy';
import AnyformAnywhere from './components/solution/AnyformAnywhere';
import Accessibility from './components/solution/Accessibility';
import Team from './components/about/Team';
import Branson from './components/about/Branson';
import History from './components/about/History';
import Videos from './components/resources/Videos';
import Subscriptions from './components/product/Subscriptions';
import CollabSummit from './components/events/CollabSummit';

import Blog from './components/blogs/Blog';

import './custom.css'

import connectors from './components/connectors/connectors.json';
import blogs from './components/blogs/blogs.json';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';

import Pricing from './components/Pricing';

export default () => {
    AOS.init({
        once: true
    });

    useEffect(() => {
        window.addEventListener('load', () => {
            document.querySelector('body')?.classList.add('loaded');
        });
    });

    return (
        <div>
            <div className="spinner-loader bg-tint-primary">
                <div className="spinner-border text-primary" role="status">
                </div>
                {/*<span className="small d-block ms-2">Loading...</span>*/}
            </div>

            <Layout>
                <Suspense fallback={<Home />}>
                    <Switch>
                        <Route exact path='/' component={Home} />
                        <Route path='/branson' component={Branson} />
                        <Route path='/contact-us' component={ContactUs} />
                        <Route path='/video-gallery' component={Videos} />

                        {/* product - overview */}
                        <Route path='/product/desktop' component={Desktop} />
                        <Route path='/product/generative-ai' component={GenerativeAI} />
                        <Route path='/product/uniform' component={Uniform} />
                        <Route path='/product/recognition-services' component={RecognitionServices} />
                        <Route path='/product/connector-framework' component={ConnectorFramework} />
                        <Route path='/product/subscriptions' component={Subscriptions} />

                        {/* product - solutions */}
                        <Route path='/solution/infopath-replacement' component={InfoPathReplacement} />
                        <Route path='/solution/infopath-consultancy' component={InfoPathConsultancy} />
                        <Route path='/solution/anyform-anywhere' component={AnyformAnywhere} />
                        <Route path='/solution/Accessibility' component={Accessibility} />

                        {/* product - connectors */}
                        {connectors?.map((connector, index) => (                        
                            <Route path={`/connectors/${connector?.id}`} component={lazy(() => import(`./components/connectors/${connector.id}`))} key={index.toString()} />
                        ))}

                        {/* blog */}
                        {blogs?.map((blog, index) => (
                            <Route path={`/blog/${blog?.slug}`} component={lazy(() => import(`./components/blogs/${blog.id}`))} key={index.toString()} />
                        ))}

                        <Route exact path='/blog' component={Blog} />

                        {/* about */}
                        <Route path='/about/team' component={Team} />
                        {/*<Route exact path='/about/history' component={History} />*/}

                        <Route path='/privacy-policy' component={PrivacyPolicy} />
                        <Route path='/terms-of-use' component={TermsOfUse} />

                        {/* events */}
                        <Route path='/events/european-collaboration-summit' component={CollabSummit} />

                        {/* redirect */}
                        <Route path='/redirects/infopath-replacement' component={InfoPathReplacementRedirect} />

                        {/*<Route path='/pricing' component={Pricing} />*/}

                        <Route component={Home} />
                    </Switch>
                </Suspense>
            </Layout>
        </div>
    );
};
