import * as React from 'react';
import { connect } from 'react-redux';
import ContactUsFooter from '../ContactUsFooter'

const Subscriptions = () => (
    <div>
        <section className="position-relative mb-5 bg-gradient-blur pt-3">
            <div className="container-fluid px-xl-9 position-relative z-index-1">
                <div className="row align-items-center col-12 col-lg-10 offset-lg-1 mb-3">
                    <h2 className="display-4">
                        Subscriptions
                    </h2>
                </div>
                <div className="row align-items-center col-12 col-lg-10 offset-lg-1">
                    <h2 >
                        Anyform Native
                    </h2>
                    <p>The holder of this subscription can convert the number of forms represented in the purchased form pack to any supported native experience.</p>
                    <p>                            
                        Each form converted to a native experience (e.g. Power Apps or K2 SmartForms) will count as one form for the purpose of measuring usage and compliance with the subscription.  Re-writing/converting the same form to the same native platform <b>will not</b> increment any usage counters.  Deleting an already written form <b>will not</b> decrement usage counters.  You can send an email to <a href="mailto:helpdesk@kudzusoftware.com">helpdesk@kudzusoftware.com</a> certifying that you deleted the form from the destination platform and request a decrement in your usage counter.
                    </p>
                    <p>
                        Upon expiration or termination of this subscription, forms written to native experiences <b>will</b> continue to work. 
                    </p>
                    <h2>
                        Anyform Anywhere
                    </h2>
                    <p>The holder of this subscription can publish the number of forms represented in the purchased form pack to any supported experience that leverages the Uniform Runtime.</p>
                    <p>
                        Each published form that leverages the Uniform Runtime will count as one form for the purpose of measuring usage and compliance with the subscription.  Re-publishing a form <b>will not</b> increment any usage counters.  Deleting an already published form <b>will</b> decrement usage counters.
                    </p>
                    <p>
                        Upon expiration or termination of this subscription, published forms <b>will not</b> continue to work.
                    </p>
                    <h2>
                        Data Migration
                    </h2>
                    <p>The holder of this subscription can migrate the number of data units represented in the purchased pack to any supported destination. A <b>Data Unit</b> is defined as <i>the full dataset associated with a single filled out instance of a source form</i>.</p>
                    <p>
                        Each data unit read and subsequently written to a destination platform will increment the usage count.  There is no mechanism for recognizing deletion of data on the destination, so usage counters can not be automatically decremented.  You can send an email to <a href="mailto:helpdesk@kudzusoftware.com">helpdesk@kudzusoftware.com</a> certifying that you deleted the destination data, a reason for doing so, and a request to decrement your usage counter for that operation.
                    </p>
                    <p>
                        Expiration or termination of this subscription has no affect on migrated data.
                    </p>
                    <h2>
                        Kudzu Accessibility Maker for K2
                    </h2>
                    <p>The holder of this subscription can make existing K2 Smartforms accessible up to the form count represented in the purchased form pack.</p>
                    <p>
                        Each form that is made accessible will increment the usage counter. Removing the accessibility extensions from a SmartForm will decrement the usage counter.
                    </p>
                    <p>
                        Upon expiration or termination of this subscription, forms that were made accessible <b>will</b> continue to work but <b>will not</b> be updated with any new accessibility scripts, styling, tagging, or metadata.
                    </p>
                </div>
            </div>
        </section>
        <ContactUsFooter />
    </div>
);

export default connect()(Subscriptions);
