import * as React from 'react';
import { connect } from 'react-redux';
import { SuperSEO } from 'react-super-seo';
import ContactUsFooter from '../ContactUsFooter'
import './Team.css'

const Team = () => (
    <div>
        <SuperSEO
            title="Team - About Kudzu"
            description="Kudzu was formed to build a platform that would aid organizations in moving digital transformation initiatives forward by automating user experience and data migration workloads."
            lang="en"
            googleSiteVerification="1hS9bBPIOWWfEIJ33ELv_Z75P9JK2Idlcc-TFCB8_lU"
            openGraph={{
                ogImage: {
                    ogImage: `https://${location.host}/images/kudzu-hero.png`,
                    ogImageAlt: 'Team - About Kudzu',
                    ogImageType: 'image/png'
                },
                ogType: 'website',
                ogLocale: 'en-US',
                ogTitle: 'Team - About Kudzu',
                ogSiteName: 'Kudzu',
                ogUrl: location.href
            }}
            twitter={{
                twitterSummaryCardWithLargeImage: {
                    summaryCardTitle: 'Team - About Kudzu',
                    summaryCardImage: `https://${location.host}/images/kudzu-hero.png`,
                    summaryCardImageAlt: 'Team - About Kudzu',
                    summaryCardSiteUsername: 'kudzusoftware'
                }
            }}
        />
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-11 offset-lg-1">
                        <h1 className="display-3 mb-4">
                            <span>Meet the Team</span>
                        </h1>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6 offset-lg-1">
                        <div className="lead">
                            <p>Kudzu was founded by a team of veteran business process automation and content management experts. Over the last 20 years we've witnessed organizations of all shapes and sizes struggle to initiate, plan, and execute digital transformation initiatives.</p>
                            <p>We formed Kudzu with the idea that we could build a platform that would aid organizations in moving digital transformation initiatives forward by automating user experience and data migration workloads, making both more accessible along the way...Any Form, Anywhere, for Anyone.</p>
                        </div>
                    </div>                    
                    <div className="col-12 col-sm-12 col-lg-2 team-member">
                        <img src="images/people/rob.jpg" className="img-fluid position-relative" />
                        <div className="name">Rob D'Oria</div>
                        <div className="title">CO-FOUNDER, President & CEO</div>
                        <a href="https://www.linkedin.com/in/robdoria/" target="_blank"><img src="images/brands/linkedin.svg" className="linked-in" /></a>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-2 team-member">
                        <img src="images/people/eric.jpg" className="img-fluid position-relative" />
                        <div className="name">Eric Eichler</div>
                        <div className="title">CO-FOUNDER AND CPO</div>
                        <a href="https://www.linkedin.com/in/ericeichler/" target="_blank"><img src="images/brands/linkedin.svg" className="linked-in" /></a>
                    </div>
                </div>                
            </div>
        </section>
        <ContactUsFooter />
    </div>
);

export default connect()(Team);
