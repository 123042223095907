import * as React from 'react';
import { connect } from 'react-redux';

const PrivacyPolicy = () => {

    return (
        <section className="position-relative mb-5 pt-5">
            <div className="container-fluid position-relative z-index-1 p-5">
                <div className="mx-5">  
                    <h1 className="display-3 mb-4">
                        <span>Privacy Policy</span>
                    </h1>
                    <p><b>Effective date: April 5, 2022</b></p>
                    <p>
                        The website <a href="www.kudzusoftware.com">www.kudzusoftware.com</a> ("the Site") is owned and operated by Kudzu Software, Inc.  ("Company," "we," "our," or "us"). The Site provides information regarding our proprietary software which may be used to convert, create, manage and/or automate a customer's business form documents and presentations at promotional events and trade shows (the "Services").   Company does not meet the definition of a "business" under the California Consumer Privacy Act.  Company does not direct products or services to residents of the United Kingdom or the European Economic Area.
                    </p>
                    <p>This Privacy Policy informs you of our policies regarding the collection, use, and disclosure of personal information when you use our Site and Services and the choices you have associated with that information.</p>
                    <p>We collect your information only when you provide it to us, and we collect only the minimum amount of information necessary. We do not sell or rent your information to third parties.</p>
                    <p>
                        <b>Information We Collect</b>
                        <br/>
                        <i>Personal Information</i>
                        <br />
                        While using our Services, we may ask you to provide us, or we may collect certain personally identifiable information that can be used to contact or identify you ("Personal Information")
                    </p>
                    <p>We collect Personal Information that you choose to provide to us when you contact us to request information regarding our Services or when you otherwise use the Services.</p>
                    <p>When you contact us, sign up for more information or reach out to us with a question, we will collect your name, email address, company, and phone number.</p>
                    <p>When you contact us through the Site, you may also decide to convey other Personal Information at your discretion</p>
                    <p>
                        <i>Usage Information</i>
                        <br />
                        We also collect analytical information such as the time of your visit, pages visited, and time spent on each page of the webpages; referring site details; network location; and products you viewed, browser type, operating system, IP address, and device information ("Analytical Information").
                    </p>
                    <p>
                        <b>How We Use Your Information</b>
                        <br />
                        We use your Personal Information to provide you the Services, and to receive and respond to your inquiries about products, services, or support. To address your questions, we may subsequently need to request additional information. We may use your Personal Information to contact you about products or services that we believe may be of interest to you. We will use your Personal Information to register your account.
                    </p>
                    <p>When you use the Site and Services, we may automatically collect and use Analytical Information in order for us to track your movement on the Site to improve our layout and page arrangement; receive information on your browser type to ensure our Site remains compatible with the browsers used by our customers; improve your experience with our Services; and to promote our Services. We use your Analytical Information in the aggregate to determine market trends and trends in customer behavior. </p>
                    <p>
                        <b>Information Sharing and Disclosure</b>
                        <br />
                        We may share your Personal Information to provide a product or service you have requested. 
                    </p>
                    <p>We may share your Personal Information with service providers that perform tasks on our behalf related to our business, such as order fulfillment services, analytical services, marketing services that may provide you advertising on our behalf, bookkeeping, customer service, employment services, or social media services.</p>
                    <p>We may disclose your Personal Information as required or appropriate by law, including to comply with legal process and to respond to requests from public and government authorities; to enforce our terms and conditions, including investigations of potential violations thereof; to detect, prevent, or otherwise address fraud, security, or technical issues; and to protect our operations.</p>
                    <p>We may otherwise share or disclose your Personal Information with your consent.</p>
                    <p>We may also share your Personal Information with a company that acquires our business, whether through merger, acquisition, bankruptcy, dissolution, reorganization, or other similar transaction or proceeding.</p>
                    <p>
                        <b>Your Choices</b>
                        <br />
                        You may limit collection of certain of your Personal Information by not sharing it with us. However, it is important to remember that if you limit the collection of your information, the Services may not function properly, and we may not be able to respond to your questions.
                    </p>
                    <p>We store your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer period is permitted or required by law.</p>
                    <p>
                        <b>Cookies</b>
                        <br />
                        We may use "cookies," a small text file transferred to your device, along with similar technologies (e.g., internet tag technologies, web beacons, embedded scripts) to help provide you a better, more personalized user experience. These technologies are used to:
                        <ul>
                            <li>Make the user experience more user friendly;</li>
                            <li>Remember your preferences (e.g., browsing language, usage preferences); or</li>
                            <li>Help us understand and improve how visitors use our Site, including which of our pages and products are viewed most frequently.</li>
                        </ul>
                    </p>
                    <p>Cookies can be blocked by changing your internet browser settings to refuse all or some cookies. If you choose to block all cookies (including essential cookies) you may not be able to access all or parts of the Service.</p>
                    <p>You can find out more about cookies and how to manage them by visiting <a href="www.AboutCookies.org" target="_blank">www.AboutCookies.org</a>.</p>
                    <p>You can understand which third parties have currently enabled cookies for your browser or mobile device and how to opt-out of some of those cookies by accessing the <a href="http://optout.networkadvertising.org/#!/" target="_blank">Network Advertising Initiative's</a> website or the <a href="http://optout.aboutads.info/#!/" target="_blank">Digital Advertising Alliance's</a> website. For more information on mobile specific opt-out choices, visit the <a href="http://www.networkadvertising.org/mobile-choices" target="_blank">Network Advertising Initiative's Mobile Choices</a> website.</p>
                    <p>
                        <b>Google Analytics</b>
                        <br />
                        We use Google Analytics, a web analytics service provided by Google, Inc. Google Analytics uses Cookies or other tracking technologies to help us analyze how users interact with the Site and Services, compile reports on their activity, and provide other services related to their activity and usage. The technologies used by Google may collect information such as your IP address, time of visit, whether you are a returning visitor, and any referring website. The technologies used by Google Analytics do not gather information that personally identifies you. The information generated by Google Analytics will be transmitted to and stored by Google and will be subject to Google's <a href="https://policies.google.com/privacy?hl=en" target="_blank">privacy policies</a>. To learn more about Google's partner services and to learn how to opt-out of tracking of analytics by Google, <a href="https://policies.google.com/technologies/partner-sites" target="_blank">click here</a>.
                    </p>
                    <p>
                        <b>Internet-Based Ads</b>
                        <br />
                        Unaffiliated third parties may use cookies and other technologies on third-party websites to collect information about your online activities over time and across different websites you visit in order to provide you with interest-based advertising. You can generally opt-out of receiving interest-based advertisements from members of the Network Advertising Initiative by clicking <a href="http://www.networkadvertising.org/choices/" target="_blank">here</a> or the Digital Advertising Alliance by clicking <a href="http://www.aboutads.info/choices/" target="_blank">here</a>. When you opt-out using these links, please note that you may still receive advertisements. However, advertisements will not be personalized based on your interests.
                    </p>
                    <p>
                        <b>Do Not Track</b>
                        <br />
                        Some internet browsers incorporate a "Do Not Track" feature that signals to websites you visit that you do not want to have your online activity tracked. Given that there is not a uniform way that browsers communicate the "Do Not Track" signal, the website does not currently interpret, respond to or alter its practices when it receives "Do Not Track" signals.
                    </p>
                    <p>
                        <b>Service Providers</b>
                        <br />
                        We may employ third-party companies and individuals to facilitate our Services ("Service Providers"), to provide the Services on our behalf, and to assist us in analyzing how our Services are used. We take commercially reasonable measures to contract with Service Providers that implement commercially reasonable protections when processing your Personal Information
                    </p>
                    <p>
                        <b>Links to Other Sites</b>
                        <br />
                        Our Services may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's website. We strongly advise you to review the Privacy Policy of every website you visit.
                    </p>
                    <p>We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services.</p>
                    <p>
                        <b>Security</b>
                        <br />
                        We maintain reasonable technical safeguards to protect the security, integrity and privacy of your Personal Information. However, no method of Internet transmission or electronic storage is 100% secure or error-free, so it is never possible to guarantee absolute security.
                    </p>
                    <p>
                        <b>Notice to California residents</b>
                        <br />
                        We do not disclose your personal information to any third party for such third party';s direct marketing purposes. Thus, under California Civil Code Sections 1798.80-1798.84, upon your request, we have no obligation to provide you further information. Any inquiries regarding this Privacy Policy can be directed to the contact identified below.
                    </p>
                    <p>
                        <b>Notice to Nevada residents</b>
                        <br />
                        Nevada law allows Nevada residents to opt out of the sale of certain types of personal information. Subject to several exceptions, Nevada law defines "sale" to mean the exchange of certain types of personal information for monetary consideration to a person for the person to license or sell the information to additional persons. We do not currently sell personal information as defined in the Nevada law. However, if you are a Nevada resident, you still may submit a verified request to opt out of sales and we will record your instructions and incorporate them in the future if our policy changes. Opt-out requests may be sent to us through one of the methods provided in the "Contact Us" section below and put "Nevada Opt-Out" in the subject line.
                    </p>
                    <p>
                        <b>Notice to Utah residents</b>
                        <br />
                        We do not disclose your personal information to any third party for such third party's direct marketing purposes.
                    </p>
                    <p>
                        <b>Commitment to Children's Privacy</b>
                        <br />
                        In compliance with the Children's Online Privacy Protection Act, 15 U.S.C. &sect;&sect; 6501-06 and 16 C.F.R., &sect;&sect; 312.1-312.12, this website does not collect information from children under thirteen (13) years of age, and we do not intentionally collect information from persons under thirteen (13) years of age. Use of the Services is limited to users that are eighteen (18) years of age and older. By using the Services, you represent that you are eighteen (18) years of age or older.
                    </p>
                    <p>
                        <b>Changes to this Privacy Policy</b>
                        <br />
                        We reserve the right to change, modify or otherwise update this policy at any time. Unless otherwise noted, any changes to this Privacy Policy will be effective immediately. If any changes are material, we may provide you notice of such changes by posting notice on the website or by other means.
                    </p>
                    <p>
                        <b>Contact Us</b>
                        <br />
                        If you have any questions or comments about this Privacy Policy, please contact us <b>Kudzu Software, Inc.</b>, at <a href="mailto:sales@kudzusoft.com">sales@kudzusoft.com</a>.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default connect()(PrivacyPolicy);
