import React, { useEffect } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import '../../node_modules/animate.css/animate.min.css';
import connectors from './connectors/connectors.json';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean, productOpen: boolean, resourcesOpen: boolean, aboutOpen: boolean }> {
    public state = {
        isOpen: false,
        productOpen: false,
        resourcesOpen: false,
        aboutOpen: false
    };

    public render() {
        return (
            <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom p-0 sticky-top" dark>
                <Container className="no-wrap">
                    <NavbarToggler onClick={this.toggle} className="mr-2"/>
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <NavbarBrand tag={Link} to="/">
                            <img src="images/logo-sm.png" alt="Kudzu Software" className="img-fluid logo-sm" />
                        </NavbarBrand>
                        <ul className="navbar-nav ms-auto">
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                            </NavItem>
                            <Dropdown nav isOpen={this.state.productOpen} toggle={() => this.toggleProductMenu} onMouseEnter={() => this.toggleProductMenu(true)} onMouseLeave={() => this.toggleProductMenu(false)}>
                                <DropdownToggle nav>
                                    Product
                                </DropdownToggle>
                                <DropdownMenu className="nav-multi-column-3 animate__animated animate__fast animate__fadeIn" end>               
                                    <div className="row nav-row">
                                        <div className="col-12 col-lg-4 nav-column">
                                            <DropdownItem header>OVERVIEW</DropdownItem>
                                            <DropdownItem tag="a" href="/product/desktop">Kudzu Desktop</DropdownItem>
                                            <DropdownItem tag="a" href="/product/generative-ai">Form Pilot</DropdownItem>
                                            <DropdownItem tag="a" href="/product/uniform">Uniform</DropdownItem>
                                            <DropdownItem tag="a" href="/product/connector-framework">Connector Framework</DropdownItem>
                                            <DropdownItem tag="a" href="/product/recognition-services">Recognition Services</DropdownItem>
                                        </div>
                                        <div className="col-12 col-lg-4 nav-column">
                                            <DropdownItem header>CONNECTORS</DropdownItem>
                                            {connectors?.map(function (connector, index) {
                                                return <DropdownItem tag="a" href={`/connectors/${connector?.id}`} key={index.toString()}>{connector?.name}</DropdownItem>;
                                            })}
                                        </div>
                                        <div className="col-12 col-lg-4 nav-column">
                                            <DropdownItem header>SOLUTIONS</DropdownItem>
                                            <DropdownItem tag="a" href="/solution/infopath-replacement">InfoPath Replacement</DropdownItem>
                                            <DropdownItem tag="a" href="/solution/infopath-consultancy">InfoPath Consultancy</DropdownItem>
                                            <DropdownItem tag="a" href="/solution/accessibility">Accessibility</DropdownItem>
                                        </div>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                            {/*<NavItem>*/}
                            {/*    <NavLink tag="a" href="/pricing" className="text-dark">Pricing</NavLink>*/}
                            {/*</NavItem>*/}
                            <Dropdown nav isOpen={this.state.resourcesOpen} toggle={() => this.toggleResourcesMenu} onMouseEnter={() => this.toggleResourcesMenu(true)} onMouseLeave={() => this.toggleResourcesMenu(false)}>
                                <DropdownToggle nav>
                                    Resources
                                </DropdownToggle>
                                <DropdownMenu className="animate__animated animate__fast animate__fadeIn">
                                    <DropdownItem tag="a" href="/blog">Blog</DropdownItem>
                                    <DropdownItem tag="a" href="/video-gallery">Videos</DropdownItem>
                                    <DropdownItem tag="a" href="https://formulator.kudzusoftware.com" target="_blank">the FORMulator</DropdownItem>
                                {/*    <DropdownItem>FAQs</DropdownItem>*/}
                                {/*    <DropdownItem>News and Articles</DropdownItem>*/}
                                {/*    <DropdownItem>Events</DropdownItem>*/}
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown nav isOpen={this.state.aboutOpen} toggle={() => this.toggleAboutMenu} onMouseEnter={() => this.toggleAboutMenu(true)} onMouseLeave={() => this.toggleAboutMenu(false)}>
                                <DropdownToggle nav>
                                    About
                                </DropdownToggle>
                                <DropdownMenu className="animate__animated animate__fast animate__fadeIn">
                                    <DropdownItem tag="a" href="/about/team">Team</DropdownItem>
                                    {/*<DropdownItem>History</DropdownItem>*/}
                                </DropdownMenu>
                            </Dropdown>
                            <NavItem>
                                <NavLink tag="a" href="/contact-us" className="text-dark">Contact</NavLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    private toggleProductMenu = (flag: boolean | undefined) => {
        this.setState({
            productOpen: flag === undefined ? !this.state.productOpen : flag
        });
    }

    private toggleResourcesMenu = (flag: boolean | undefined) => {
        this.setState({
            resourcesOpen: flag === undefined ? !this.state.resourcesOpen : flag
        });
    }

    private toggleAboutMenu = (flag: boolean | undefined) => {
        this.setState({
            aboutOpen: flag === undefined ? !this.state.aboutOpen : flag
        });
    }
}
