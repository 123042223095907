import * as React from 'react';
import { connect } from 'react-redux';
import { SuperSEO } from 'react-super-seo';
import ContactUsFooter from '../ContactUsFooter'
import { ConnectorFooter } from '../connectors/Connector';
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Navigation, Pagination } from 'swiper'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

const ConnectorFramework = () => (
    <div>
        <SuperSEO
            title="Connector Framework - Kudzu"
            lang="en"
            googleSiteVerification="1hS9bBPIOWWfEIJ33ELv_Z75P9JK2Idlcc-TFCB8_lU"
            openGraph={{
                ogImage: {
                    ogImage: `https://${location.host}/images/kudzu-hero.png`,
                    ogImageAlt: 'Connector Framework - Kudzu',
                    ogImageType: 'image/png'
                },
                ogType: 'website',
                ogLocale: 'en-US',
                ogTitle: 'Connector Framework - Kudzu',
                ogSiteName: 'Kudzu',
                ogUrl: location.href
            }}
            twitter={{
                twitterSummaryCardWithLargeImage: {
                    summaryCardTitle: 'Connector Framework - Kudzu',
                    summaryCardImage: `https://${location.host}/images/kudzu-hero.png`,
                    summaryCardImageAlt: 'Connector Framework - Kudzu',
                    summaryCardSiteUsername: 'kudzusoftware'
                }
            }}
        />
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-3 mb-4">
                            <span>Connector Framework</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <p>Kudzu supports an ever-growing list of source and target form platforms through our Connector Framework.  Kudzu connectors have the ability to read and/or write a form's:</p>
                            <ul>
                                <li>Layout &amp; Style</li>
                                <li>Input &amp; Labeling elements</li>
                                <li>Schema</li>
                                <li>Sources</li>
                                <li>Rules</li>
                                <li>Data</li>
                            </ul>
                            <p>Depending on the connector, some or all of these capabilities are available. Check out the individual connector pages for details.</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                        <img src="images/kudzu-hero.png" alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
        </section>
        <ConnectorFooter />
        <section className="position-relative overflow-hidden bg-gradient-blur my-5 mx-5">
            <div className="container-fluid px-xl-9 position-relative py-9 py-lg-11">
                <div className="row my-5">
                    <div className="col-lg-8 mx-auto text-center col-xl-7">
                        <h2 className="mb-6 mb-lg-9 display-5" data-aos="fade-down" data-aos-delay="100">
                            Connector Request
                        </h2>
                        <div className="lead display-6">
                            <p>Don't see the connector you need?</p>
                        </div>
                    </div>
                    <div className="col-lg-8 mx-auto text-center col-xl-7">
                        <p>While you can expect us to add support for additional legacy form types and mockup/wireframe tools on the reader side, along with support for embedded experiences on popular SaaS platforms on the writer side, we're interested in hearing from you.</p>                       
                        <a href="/contact-us/?inquiry=Connector%20Request" className="btn btn-lg btn-primary">LET US KNOW</a>
                    </div>
                </div>
            </div>
        </section>
        <ContactUsFooter />
    </div>
);

export default connect()(ConnectorFramework);
