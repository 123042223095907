import * as React from 'react';
import { connect } from 'react-redux';
import { SuperSEO } from 'react-super-seo';
import ContactUsFooter from '../ContactUsFooter'
import { ConnectorFooter } from '../connectors/Connector';
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Navigation, Pagination } from 'swiper'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

const GenerativeAI = () => (
    <div>
        <SuperSEO
            title="Generative AI - Kudzu"
            lang="en"
            googleSiteVerification="1hS9bBPIOWWfEIJ33ELv_Z75P9JK2Idlcc-TFCB8_lU"
            openGraph={{
                ogImage: {
                    ogImage: `https://${location.host}/images/blogs/KudzuBotTheBuilder-hero.png`,
                    ogImageAlt: 'Generative AI - Kudzu',
                    ogImageType: 'image/png'
                },
                ogType: 'website',
                ogLocale: 'en-US',
                ogTitle: 'Generative AI - Kudzu',
                ogSiteName: 'Kudzu',
                ogUrl: location.href
            }}
            twitter={{
                twitterSummaryCardWithLargeImage: {
                    summaryCardTitle: 'Generative AI - Kudzu',
                    summaryCardImage: `https://${location.host}/images/blogs/KudzuBotTheBuilder-hero.png`,
                    summaryCardImageAlt: 'Generative AI - Kudzu',
                    summaryCardSiteUsername: 'kudzusoftware'
                }
            }}
        />
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-3 mb-4">
                            <span>Form Pilot</span>
                        </h1>
                        <div className="position-relative display-6 mb-5">
                            <p><b>Imagine</b> a new form, Completely <b>Re-imagine</b> an existing form, or just make an existing form better.</p>
                            <p>Form Pilot is your <b>AI-Powered Citizen Developer!</b></p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                        <img src="images/blogs/KudzuBotTheBuilder-hero.png" alt="" className="img-fluid shadow" />
                    </div>
                </div>
            </div>
        </section>
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-6">
                        <img src="images/kudzu-bot-imagine.png" alt="" className="img-fluid shadow" />
                    </div>
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-3 mb-4">
                            <span>Have an idea for a new form?</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <p>Form Pilot can take that idea, work with one of the LLMs we support, create a Uniform model, and populate choice lists with meaningful options. Oh, and Form Pilot will even produce a user-friendly name for the form.</p>
                            <p>Once inside the Kudzu Imaginer, Form Pilot will continue to help you make the form even better before publishing it to any of the modern low and no code technologies Kudzu supports.</p>
                            <a href="https://youtu.be/sDxlLxlSQ4k" target="_blank" className="btn btn-lg btn-primary">See it in action</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-3 mb-4">
                            <span>Have an outdated form that needs some help?</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <p>Form Pilot can take an existing form, like InfoPath, and help you populate single and multiple choice lists, add new fields, and even add entire new sections of fields.</p>
                            <p>Breath life into those outdated forms by replacing free text fields with user-friendly choice lists or updating existing choice lists with values that reflect the times.</p>                           
                            <a href="https://youtu.be/j5RwuFZP3U8" target="_blank" className="btn btn-lg btn-primary">See it in action</a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                        <img src="images/kudzu-bot-reimagine.png" alt="" className="img-fluid shadow" />
                    </div>
                </div>
            </div>
        </section>

        <section className="position-relative mb-5 bg-gradient-blur pt-3">
            <div className="container-fluid px-xl-9 position-relative z-index-1">
                <div className="row mb-5">
                    <div className="col-lg-8 mx-auto text-center col-xl-7">
                        <h2 className="display-4">
                            See it in action
                        </h2>
                    </div>
                </div>
                <div className="container-fluid px-xl-9 position-relative z-index-1 my-5">
                    <div className="row align-items-center col-12 col-lg-6 offset-lg-3">
                        <Swiper spaceBetween={100} modules={[Navigation, Pagination, EffectCoverflow]} effect="coverflow" speed={750} navigation={true} className="px-5"
                            onSlideChange={(swiperCore) => {
                                var player = swiperCore.slides[swiperCore.previousIndex].querySelector('iframe');
                                player?.contentWindow?.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                            }}>
                            <SwiperSlide>
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/j5RwuFZP3U8?enablejsapi=1" title="YouTube video player"
                                        frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/sDxlLxlSQ4k?enablejsapi=1" title="YouTube video player"
                                        frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/0U3u2D2UQ1o?enablejsapi=1" title="YouTube video player"
                                        frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>

        <ContactUsFooter />
    </div>
);

export default connect()(GenerativeAI);
