import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faEye, faCopy, faFileImport, faPenToSquare, faDatabase, faFileCode, faFont, faCloud, faChartColumn, faCogs, faLightbulb, faWheelchair } from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFlip, EffectCoverflow, Autoplay, Navigation, Pagination } from 'swiper'
import ContactUsFooter from './ContactUsFooter'
import './Home.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-flip';
import 'swiper/css/effect-coverflow';

import connectors from './connectors/connectors.json';

const Home = () => {

    const [slide, setSlide] = useState(1);

    SwiperCore.use([]);

    return (
        <div>
            <section className="position-relative bg-gradient-blur mb-5 pt-5">
                <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12 col-lg-7 me-xl-auto z-index-1 text-center">
                            <h1 className="display-3 mb-4">
                                <span>Automation-assisted<br/>Forms Conversion</span>
                            </h1>
                            <div className="lead display-6 text-left">
                                <span>Kudzu's </span>
                                <span className="position-relative d-inline-block">patented middleware
                                    <svg className="position-absolute start-0 bottom-0 text-warning z-index-n1" preserveAspectRatio="none"
                                        width="100%" height="6" viewBox="0 0 63 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.119995 2.79013C0.119995 2.79013 26.91 -0.589865 62.82 1.93013" stroke="#14A76A"
                                            strokeWidth="1" />
                                    </svg>
                                </span>
                                <span> converts outdated forms, wireframes, and AI-generated models into modern user experiences in leading low code and no code technologies<br/>...</span>
                                <span className="position-relative d-inline-block">in a few clicks!
                                    <svg className="position-absolute start-0 bottom-0 text-warning z-index-n1" preserveAspectRatio="none"
                                        width="100%" height="6" viewBox="0 0 63 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.119995 2.79013C0.119995 2.79013 26.91 -0.589865 62.82 1.93013" stroke="#14A76A"
                                            strokeWidth="1" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-5">
                            <div className="position-relative text-center">
                                <img src="images/kudzu-hero-lg.png" alt="" className="w-100" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="position-relative overflow-hidden my-5 mx-5">
                <div className="container-fluid px-xl-9 position-relative py-9 py-lg-11">
                    <div className="row my-5">
                        <div className="col-lg-8 mx-auto text-center col-xl-7">
                            <h2 className="mb-6 mb-lg-9 display-5" data-aos="fade-down" data-aos-delay="100">
                                Digital Transformation Realized
                            </h2>
                        </div>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-md-6 col-lg-5 offset-lg-1 mb-4" data-aos="fade-down">
                            <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                                <FontAwesomeIcon icon={faCogs} size="2x" color="#14A76A" className="mb-4 me-4" />
                                <div>
                                    <h5>Accelerate Process Automation</h5>
                                    <p className="mb-0">
                                        Newly converted forms can easily participate in workflows authored on popular automation platforms from Microsoft, Nintex, K2 and others.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 me-auto mb-4" data-aos="fade-down" data-aos-delay="100">
                            <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                                <FontAwesomeIcon icon={faCloud} size="2x" color="#14A76A" className="mb-4 me-4" />
                                <div>
                                    <h5>Enhance Existing SaaS Investments</h5>
                                    <p className="mb-0">
                                        Easily surface converted forms in a wide range of popular CMS, DPA, CRM, HRIS, and ITOM SaaS platforms.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-4" data-aos="fade-down" data-aos-delay="100">
                            <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                                <FontAwesomeIcon icon={faChartColumn} size="2x" color="#14A76A" className="mb-4 me-4" />
                                <div>
                                    <h5>Improve Insights</h5>
                                    <p className="mb-0">
                                        Newly unlocked and readily-accessible data can now be used to provide enhanced analytics and improve decision-making.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 me-auto mb-4" data-aos="fade-down" data-aos-delay="100">
                            <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                                <FontAwesomeIcon icon={faLightbulb} size="2x" color="#14A76A" className="mb-4 me-4" />
                                <div>
                                    <h5>Expedite Ideation</h5>
                                    <p className="mb-0">
                                        Ideate new forms in toolsets that are already familiar to staff designing new user experiences and quickly turn them into working forms.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 offset-lg-1 mb-4" data-aos="fade-down" data-aos-delay="100">
                            <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                                <FontAwesomeIcon icon={faWheelchair} size="2x" color="#14A76A" className="mb-4 me-4" />
                                <div>
                                    <h5>Be Accessible to Everyone</h5>
                                    <p className="mb-0">
                                        Automate Section 508 and WCAG 2.1 compliance for (all) your forms, whether you're converting existing forms or creating new ones.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-4 me-auto" data-aos="fade-down" data-aos-delay="100">
                            <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                                <FontAwesomeIcon icon={faFile} size="2x" color="#14A76A" className="mb-4 me-4" />
                                <div>
                                    <h5>Go All the Way Digital</h5>
                                    <p className="mb-0">
                                        Improve user experience and data quality by removing the many challenges associated with paper and imaged-based forms.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="position-relative bg-gradient-blur mb-5 pt-5">
                <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12 col-lg-6">
                            <div className="row">
                                {connectors?.map(function (connector, index) {
                                    return <div className="col-6 col-md-4 col-xl-3" data-aos="flip-down" key={index.toString()}>
                                        <div className="connector-pill">
                                            <img src={connector?.icon} alt="" className="img-fluid position-relative" />
                                            <div>
                                                <span>{connector?.name}</span>
                                                <a href={`/connectors/${connector?.id}/`} className="btn btn-sm btn-primary">Learn More</a>
                                            </div>
                                        </div>
                                    </div>;
                                })}
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                            <h1 className="display-3 mb-4">
                                <span>Get </span>
                                <span className="position-relative d-inline-block">Connected
                                    <svg className="position-absolute start-0 bottom-0 text-warning z-index-n1" preserveAspectRatio="none"
                                        width="100%" height="8" viewBox="0 0 63 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.119995 2.79013C0.119995 2.79013 26.91 -0.589865 62.82 1.93013" stroke="#14A76A"
                                            strokeWidth="2" />
                                    </svg>
                                </span>
                                <span>!</span>
                            </h1>
                            <div className="position-relative lead mb-5">
                                <span>Kudzu's </span>
                                <span className="position-relative d-inline-block">Connector Framework
                                    <svg className="position-absolute start-0 bottom-0 text-warning z-index-n1" preserveAspectRatio="none"
                                        width="100%" height="6" viewBox="0 0 63 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.119995 2.79013C0.119995 2.79013 26.91 -0.589865 62.82 1.93013" stroke="#14A76A"
                                            strokeWidth="1" />
                                    </svg>
                                </span>
                                <span> supports the reading and writing of forms, schemas, and data.  It starts with an existing form, mock-up, or wireframe and transforms it to a completely new form type, creating its data store and migrating its data along the way.</span>
                                <br />
                                <a href="/product/connector-framework/" className="btn btn-primary mt-3">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="position-relative bg-gradient-blur mb-5 pt-2 px-5">
                <div className="container-fluid px-xl-9 position-relative py-9 py-lg-11">
                    <div className="row my-5">
                        <div className="col-lg-8 mx-auto text-center col-xl-7">
                            <h2 className="mb-6 mb-lg-9 display-5" data-aos="fade-down" data-aos-delay="100">
                                Features
                            </h2>
                        </div>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                            <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                                <FontAwesomeIcon icon={faFileImport} size="8x" className="mb-4 me-4 feature-icon" />
                                <div className="feature-details">
                                    <h5>Form Conversion</h5>
                                    <p className="mb-0">
                                        Fast, high-fidelity conversion between different form types leveraging readers and writers built on the Kudzu Connector Framework.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                            <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                                <FontAwesomeIcon icon={faEye} size="8x" className="mb-4 me-4 feature-icon" />
                                <div className="feature-details">
                                    <h5>Form Recognition</h5>
                                    <p className="mb-0">
                                        Patented recognition layer quickly identifies inputs and their labeling element(s) in unstructured source forms.  Complex table structures, including repeating tables, are also supported.
                                    </p>
                                    <a href="/product/form-recognition/" className="btn btn-sm btn-link p-0 mt-1">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                            <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                                <FontAwesomeIcon icon={faDatabase} size="8x" className="mb-4 me-4 feature-icon" />
                                <div className="feature-details">
                                    <h5>Data Migration</h5>
                                    <p className="mb-0">
                                        Extract data from previously filled out forms and other sources into data stores that are easily accessible to modern automation and business intelligence platforms.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                            <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                                <FontAwesomeIcon icon={faCopy} size="8x" className="mb-4 me-4 feature-icon" />
                                <div className="feature-details">
                                    <h5>Uniform</h5>
                                    <p className="mb-0">
                                        Verbose model for describing a form.  It captures detailed information about a source form that can be used to re-create the form on a completely new platform.
                                    </p>
                                    <a href="/product/uniform/" className="btn btn-sm btn-link p-0 mt-1">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                            <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                                <FontAwesomeIcon icon={faPenToSquare} size="8x" className="mb-4 me-4 feature-icon" />
                                <div className="feature-details">
                                    <h5>Editor</h5>
                                    <p className="mb-0">
                                        Patented Uniform Editor gives you the tools needed to refine form models before publishing them to destination platforms.  You can add, remove, or change element types along with any of their layout, styling, or accessibility properties.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                            <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                                <FontAwesomeIcon icon={faFileCode} size="8x" className="mb-4 me-4 feature-icon" />
                                <div className="feature-details">
                                    <h5>Embeddable Runtime</h5>
                                    <p className="mb-0">
                                        Embed the Uniform run time in any number of 3rd party applications.   Any form, mock-up, or wireframe can be surfaced in applications that support standard embedding techniques. Any Form, Anywhere
                                    </p>
                                    <a href="/product/Runtime/" className="btn btn-sm btn-link p-0 mt-1">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                            <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                                <FontAwesomeIcon icon={faWheelchair} size="8x" className="mb-4 me-4 feature-icon" />
                                <div className="feature-details">
                                    <h5>Section 508 &amp; WCAG 2.1 Compliance</h5>
                                    <p className="mb-0">
                                        Consistently and automatically applies accessibility-related metadata during the conversion of existing forms and or the creation of new ones.  Critical for compliance with Section 508 and WCAG 2.1 standards.
                                    </p>
                                    <a href="/solution/accessibility/" className="btn btn-sm btn-link p-0 mt-1">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                            <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                                <FontAwesomeIcon icon={faChartColumn} size="8x" className="mb-4 me-4 feature-icon" />
                                <div className="feature-details">
                                    <h5>Analytics</h5>
                                    <p className="mb-0">
                                        Metrics gathered during read and write operations are analyzed by the Uniform Analytics API and turned into Complexity and Portability scores, saving time on planning and execution.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                            <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                                <FontAwesomeIcon icon={faFont} size="8x" className="mb-4 me-4 feature-icon" />
                                <div className="feature-details">
                                    <h5>Font Management</h5>
                                    <p className="mb-0">
                                        User-licensed fonts can be uploaded into their Kudzu tenant's font cache where they can be used when authoring or re-writing forms.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="position-relative mb-5 pt-5">
                <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">                    
                    <Swiper spaceBetween={50} modules={[Navigation, Pagination, EffectCoverflow]} effect="coverflow" speed={750} navigation={true}>
                        <SwiperSlide>
                            <div className="row align-items-center solution-slide">
                                <div className="col-12 col-sm-12 col-lg-4 offset-1 z-index-1">
                                    <h1 className="display-3 mb-4">
                                        <span>Automated InfoPath <b>Replacement</b></span>
                                    </h1>
                                    <div className="position-relative lead mb-5">
                                        <span>Kudzu's automation-assisted conversion tool can quickly transform your existing InfoPath forms into our Uniform model and from there host them with our runtime in SharePoint or re-package them as native Power Apps or Nintex K2 SmartForms.</span>
                                        <br/>
                                        <a href="/solution/infopath-replacement" className="btn btn-primary mt-5">Learn More</a>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-6">
                                    <div className="position-relative">
                                        <img src="images/infopath-replacement.png" alt="" className="w-100" />
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="row align-items-center solution-slide">
                                <div className="col-12 col-sm-12 col-lg-4 offset-1 z-index-1">
                                    <h1 className="display-3 mb-4">
                                        <span><b>Any</b>form <b>Any</b>where</span>
                                    </h1>
                                    <div className="position-relative lead mb-5">
                                        <span>The <b>Uniform Publishing Framework</b> makes it easy to take <b>any form</b> read into Kudzu and put it <b>anywhere</b> that supports standard embedding techniques. Sketch out a contact form in Balsamiq and have it embedded in multiple public-facing web properties within minutes.</span>
                                        <br/>
                                        <a href="/solution/anyform-anywhere/" className="btn btn-primary mt-5">Learn More</a>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-6">
                                    <div className="position-relative">
                                        <img src="images/anyform-anywhere.png" alt="" className="w-100" />
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="row align-items-center solution-slide">
                                <div className="col-12 col-sm-12 col-lg-4 offset-1 z-index-1">
                                    <h1 className="display-3 mb-4">
                                        <span>Section 508 and WCAG 2.1 <b>Compliance</b></span>
                                    </h1>
                                    <div className="position-relative lead mb-5">
                                        <span>Kudzu ensures forms are compliant with the latest accessibility standards by applying required and, in many cases, recommended metadata to labels and inputs as they are published.</span>
                                        <br />
                                        <a href="/solution/accessibility/" className="btn btn-primary mt-5">Learn More</a>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-6">
                                    <div className="position-relative">
                                        <img src="images/accessibility.png" alt="" className="w-100" />
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
            <ContactUsFooter />
        </div>
    )
};

export default connect()(Home);
