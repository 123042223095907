import * as React from 'react';
import { connect } from 'react-redux';
import { SuperSEO } from 'react-super-seo';
import ContactUsFooter from '../ContactUsFooter'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Navigation, Pagination } from 'swiper'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

const InfoPathReplacementRedirect = () => (
    <div>
        <SuperSEO             
            title="InfoPath Replacement - Kudzu"
            lang="en"
            googleSiteVerification="1hS9bBPIOWWfEIJ33ELv_Z75P9JK2Idlcc-TFCB8_lU"
            openGraph={{                
                ogImage: {
                    ogImage: `https://${location.host}/images/kudzu-hero.png`,
                    ogImageAlt: 'InfoPath Replacement - Kudzu',
                    ogImageType: 'image/png'
                },
                ogType: 'website',
                ogLocale: 'en-US',
                ogTitle: 'InfoPath Replacement - Kudzu',
                ogSiteName: 'Kudzu',
                ogUrl: location.href
            }}
            twitter={{
                twitterSummaryCardWithLargeImage: {
                    summaryCardTitle: 'InfoPath Replacement - Kudzu',
                    summaryCardImage: `https://${location.host}/images/kudzu-hero.png`,
                    summaryCardImageAlt: 'InfoPath Replacement - Kudzu',
                    summaryCardSiteUsername: 'kudzusoftware'
                }
            }}              
        />
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 z-index-1 lead text-center" style={{ fontSize: "24pt" }}>
                        Thank you for booking an <b>InfoPath Replacement Consultation</b> with us.<br />Please look in your inbox for an invitation to the session.
                        <p>We look forward to meeting you!</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
);

export default connect()(InfoPathReplacementRedirect);
