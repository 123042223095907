import * as React from 'react';
import { connect } from 'react-redux';
import { SuperSEO } from 'react-super-seo';
import ContactUsFooter from '../ContactUsFooter'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'

const CollabSummit = () => (
    <div>
        <SuperSEO
            title="European Collaboration Summit - Kudzu"
            lang="en"
            googleSiteVerification="1hS9bBPIOWWfEIJ33ELv_Z75P9JK2Idlcc-TFCB8_lU"
            openGraph={{
                ogImage: {
                    ogImage: `https://${location.host}/images/collab-summit-hero.png`,
                    ogImageAlt: 'European Collaboration Summit - Kudzu',
                    ogImageType: 'image/png'
                },
                ogType: 'website',
                ogLocale: 'en-US',
                ogTitle: 'European Collaboration Summit - Kudzu',
                ogSiteName: 'Kudzu',
                ogUrl: location.href
            }}
            twitter={{
                twitterSummaryCardWithLargeImage: {
                    summaryCardTitle: 'European Collaboration Summit - Kudzu',
                    summaryCardImage: `https://${location.host}/images/collab-summit-hero.png`,
                    summaryCardImageAlt: 'European Collaboration Summit - Kudzu',
                    summaryCardSiteUsername: 'kudzusoftware'
                }
            }}
        />
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-3 mb-4">
                            <span>European Collaboration Summit</span>
                        </h1>
                        <div className="position-relative display-6 mb-5">
                            <p>Welcome to our #CollabSummit event page.</p>
                            <p>Make sure you stop by Booth #16 to say hi, learn more about Automated InfoPath Replacement and how we're using AI to ideate new forms or re-imagine old ones, and enter to win a <b>LEGO Icons Porsche 911</b>!</p>                            
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                        <img src="images/collab-summit-giveaway.png" alt="LEGO Icons Porsche 911" className="img-fluid shadow" />
                    </div>
                </div>
            </div>
        </section>

        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-6 offset-1 z-index-1">
                        <h1 className="display-3 mb-4">
                            <span>Automated InfoPath Replacement</span>
                        </h1>
                        <div className="position-relative mb-5">
                            <div className="lead">
                                Kudzu's automation-assisted conversion tool can quickly replace your existing InfoPath forms with a growing number of low and no code technologies like Microsoft Power Apps, Nintex Automation Cloud, Jigx, and many more.  Most forms are converted in under a minute with just a few clicks.
                            </div>
                            <div className="row mt-5">
                                <div className="col-12 col-lg-4">
                                    <div className="card shadow-sm">
                                        <div className="card-header bg-secondary text-light text-center">
                                            ALL-IN-ONE SOLUTION
                                        </div>
                                        <div className="card-body">
                                            <ul className="fa-ul">
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" listItem />Discovery &amp; Analysis Tools</li>
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" listItem />Form Template Conversion</li>
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" listItem />Data Entity Creation</li>
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" listItem />Data Conversion &amp; Migration</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="card shadow-sm">
                                        <div className="card-header bg-secondary text-light text-center">
                                            AUTOMATED
                                        </div>
                                        <div className="card-body">
                                            <ul className="fa-ul">
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success" listItem />No Code to Write</li>
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success" listItem />No Scripts to Execute</li>
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success" listItem />Let Kudzu do ALL the Work!</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="card shadow-sm">
                                        <div className="card-header bg-secondary text-light text-center">
                                            FREE TOOLS
                                        </div>
                                        <div className="card-body">
                                            <ul className="fa-ul">
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" listItem />Discover Deployed Forms</li>
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" listItem />Analyze Form Complexity and Portability</li>
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" listItem />Detailed Reporting</li>
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" listItem />Estimate Effort and Savings</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-4">
                        <div className="position-relative">
                            <img src="images/infopath-replacement-hero.png" alt="" className="w-100 ms-5" />
                        </div>
                    </div>
                    <div className="col-12 text-center">
                        <div className="position-relative">
                            <a href="https://outlook.office365.com/owa/calendar/KudzuPlatformIntroduction@kudzusoftware.com/bookings/" className="btn btn-lg btn-primary">Book a FREE Consultation with one of our InfoPath Replacement Experts</a>
                            <a href="/solution/infopath-replacement" className="btn btn-lg btn-primary ms-3">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-6">
                        <img src="images/kudzu-bot-imagine.png" alt="" className="img-fluid shadow" />
                    </div>
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-3 mb-4">
                            <span>Have an idea for a new form?</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <p>Kudzu Bot can take that idea, work with one of the LLMs we support, create a Uniform model, and populate choice lists with meaningful options. Oh, and Kudzu Bot will even produce a user-friendly name for the form.</p>
                            <p>Once inside the Kudzu Imaginer, Kudzu Bot will continue to help you make the form even better before publishing it to any of the modern low and no code technologies Kudzu supports.</p>
                            <a href="https://youtu.be/sDxlLxlSQ4k" target="_blank" className="btn btn-lg btn-primary">See it in action</a>
                            <a href="/product/generative-ai" className="btn btn-lg btn-primary ms-3">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-3 mb-4">
                            <span>Have an outdated form that needs some help?</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <p>Kudzu Bot can take an existing form, like InfoPath, and help you populate single and multiple choice lists, add new fields, and even add entire new sections of fields.</p>
                            <p>Breath life into those outdated forms by replacing free text fields with user-friendly choice lists or updating existing choice lists with values that reflect the times.</p>                           
                            <a href="https://youtu.be/j5RwuFZP3U8" target="_blank" className="btn btn-lg btn-primary">See it in action</a>
                            <a href="/product/generative-ai" className="btn btn-lg btn-primary ms-3">Learn More</a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                        <img src="images/kudzu-bot-reimagine.png" alt="" className="img-fluid shadow" />
                    </div>
                </div>
            </div>
        </section>

        <ContactUsFooter />
    </div>
);

export default connect()(CollabSummit);
