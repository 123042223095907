import React from 'react';

export default class ContactUsFooter extends React.PureComponent<{}, {}> {

    public render() {

        return (
            <section className="position-relative bg-gradient-blur mb-5 pt-5">
                <div className="container-fluid px-xl-9 position-relative z-index-1">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12 col-lg-6 z-index-1 text-end">
                            <div className="mb-4 display-6">
                                <p>Have a question about the product?</p>
                                <p>Want to schedule a demo?</p>
                                <p>Need a quote?</p>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-12 col-sm-12 col-lg-5">
                            <a href="/contact-us/" className="btn btn-xl btn-primary">CONTACT US</a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
