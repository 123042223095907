import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ContactUsFooter from '../ContactUsFooter'
import './Blog.css';
import posts from './blogs.json';

const Blog = () => {

    useEffect(() => {
        document.querySelectorAll(".post").forEach(post =>
            post.addEventListener("click", () =>
                location.href = post.querySelector('a')?.href ?? '/'
            )
        );
    });

    return (
        <div>
            <section className="position-relative bg-gradient-blur mb-5 pt-5">
                <h1 className="display-3 mb-5 ms-5">
                    <span>Kudzu Blog</span>
                </h1>
                <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                    <div className="row mx-5">
                        {posts.map(function (post, index) {
                            if (!post.hide) {
                                return <div className="col-12 col-lg-4 col-xl-3 position-relative" key={index.toString()}>
                                    {/*<span className="post-date">{post.published}</span>*/}
                                    <div className="post">
                                        <div className="post-hero">
                                            <img src={post.hero} alt="" className="w-100 position-relative" />
                                        </div>
                                        <div className="post-details p-3">
                                            <a href={"/blog/" + post.slug} className="post-link mb-3 ms-3">{post.title}</a>
                                            <img src={post.author.avatar} alt="" className="img-fluid position-relative post-author me-2 ms-3" />
                                            <span className="post-author">{post.author.name}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                        })}
                    </div>
                </div>
            </section>
            <ContactUsFooter />
        </div>
    );
}

export default connect()(Blog);
