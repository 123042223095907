import * as React from 'react';
import { connect } from 'react-redux';
import { SuperSEO } from 'react-super-seo';
import ContactUsFooter from '../ContactUsFooter'
import { ConnectorFooter } from '../connectors/Connector';
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Navigation, Pagination } from 'swiper'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

const Desktop = () => (
    <div>
        <SuperSEO
            title="Desktop - Kudzu"
            lang="en"
            googleSiteVerification="1hS9bBPIOWWfEIJ33ELv_Z75P9JK2Idlcc-TFCB8_lU"
            openGraph={{
                ogImage: {
                    ogImage: `https://${location.host}/images/blogs/KudzuBotTheBuilder-hero.png`,
                    ogImageAlt: 'Desktop - Kudzu',
                    ogImageType: 'image/png'
                },
                ogType: 'website',
                ogLocale: 'en-US',
                ogTitle: 'Desktop - Kudzu',
                ogSiteName: 'Kudzu',
                ogUrl: location.href
            }}
            twitter={{
                twitterSummaryCardWithLargeImage: {
                    summaryCardTitle: 'Desktop - Kudzu',
                    summaryCardImage: `https://${location.host}/images/blogs/KudzuBotTheBuilder-hero.png`,
                    summaryCardImageAlt: 'Desktop - Kudzu',
                    summaryCardSiteUsername: 'kudzusoftware'
                }
            }}
        />
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-3 mb-4">
                            <span>Kudzu Desktop</span>
                        </h1>
                        <div className="position-relative display-6 mb-5">
                            <p>All the capabilities of the <b>Kudzu Cloud</b> in a powerful, self-contained desktop client.</p>
                            <p><b>Keep your data to yourself!</b></p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                        <img src="images/kudzu-desktop-hero.png" alt="" className="img-fluid shadow" />
                    </div>
                </div>
            </div>
        </section>
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-6">
                        <img src="images/kudzu-desktop-analytics.png" alt="" className="img-fluid shadow" />
                    </div>
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-3 mb-4">
                            <span>If you liked the FORMulator, you'll love the Kudzu Desktop.</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <p>Just like the FORMulator, you can point the Kudzu Desktop at on-prem or cloud-based SharePoint instances to discover <b>InfoPath and Nintex forms</b>. You can also upload those same file types along with other supported types (e.g., PDF) from the file system and perform <b>bulk analysis and read operations</b>.</p>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-3 mb-4">
                            <span>Breath <b>new life</b> into into <b>old forms</b></span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <p>The Kudzu Desktop supports the same modern low and no code automation platforms as the Kudzu Cloud. Convert those old forms to <b>Power Apps</b>, <b>Nintex Automation Cloud</b>, <b>Lightning Forms</b>, and more.</p>                                                       
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                        <img src="images/kudzu-desktop-feature.png" alt="" className="img-fluid shadow" />
                    </div>
                </div>
            </div>
        </section>
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-6">
                        <img src="images/kudzu-desktop-data.png" alt="" className="img-fluid shadow" />
                    </div>
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-3 mb-4">
                            <span>The <b>data</b> isn't going to <b>convert</b> itself</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <p>Depending on your source form type and how data was captured and stored, you may find yourself with datasets that are not easily consumed by your destination platform of choice.  In those cases, the Kudzu Desktop can be used to migrate that data to <b>SharePoint lists</b> or <b>Dataverse tables</b>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-3 mb-4">
                            <span>Be <b>ready</b> when somebody asks for a <b>copy</b> of that form</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <p>It's not enough to just convert the fillable form template and the data as needed, internal and regulatory body <b>records retention policies</b> often dictate that a <b>unalterable copy</b> of the form be maintained.</p>
                            <p>The Kudzu Desktop can create <b>PDF archives</b> of your filled out forms and attach them to the data during conversion or drop them in a SharePoint document library with metadata from the source form.</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                        <img src="images/kudzu-desktop-archive.png" alt="" className="img-fluid shadow" />
                    </div>
                </div>
            </div>
        </section>


        <section className="position-relative mb-5 bg-gradient-blur pt-3">
            <div className="container-fluid px-xl-9 position-relative z-index-1">
                <div className="row mb-5">
                    <div className="col-lg-8 mx-auto text-center col-xl-7">
                        <h2 className="display-4">
                            See it in action
                        </h2>
                    </div>
                </div>
                <div className="container-fluid px-xl-9 position-relative z-index-1 my-5">
                    <div className="row align-items-center col-12 col-lg-6 offset-lg-3">
                        <Swiper spaceBetween={100} modules={[Navigation, Pagination, EffectCoverflow]} effect="coverflow" speed={750} navigation={true} className="px-5"
                            onSlideChange={(swiperCore) => {
                                var player = swiperCore.slides[swiperCore.previousIndex].querySelector('iframe');
                                player?.contentWindow?.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                            }}>
                            <SwiperSlide>
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/62tqnVqLkqk?enablejsapi=1" title="YouTube video player"
                                        frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>

        <ContactUsFooter />
    </div>
);

export default connect()(Desktop);
