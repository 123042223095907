import * as React from 'react';
import { connect } from 'react-redux';
import { SuperSEO } from 'react-super-seo';
import ContactUsFooter from '../ContactUsFooter'

const Branson = () => (
    <div>
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-5 offset-lg-1">
                        <h1 className="display-3 mb-4">
                            <span>Branson Event Page</span>
                        </h1>
                        <div className="position-relative lead mb-3">
                            <p><a href="https://www.youtube.com/channel/UC98WiJ9iSeUA2mfqzyGR0bg" target="_blank">Product Demo Videos</a></p>
                            <p><a href="https://www.linkedin.com/company/kudzusoftware" target="_blank">Follow us on LinkedIn</a></p>
                            <p><a href="/documents/InfoPath Anywhere.pdf" target="_blank">InfoPath Anywhere Brochure</a></p>
                        </div>                        
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6 me-xl-auto z-index-1 text-center">
                        <div className="ratio ratio-16x9">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/kKkQ4gt95rI" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <ContactUsFooter />
    </div>
);

export default connect()(Branson);
