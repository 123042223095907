import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import connectors from './connectors/connectors.json';

export default class Footer extends React.PureComponent<{}, {}> {

    public render() {

        return (
            <footer id="footer" className="position-relative bg-dark text-white mt-5">
                <div className="container-fluid py-5 px-5">
                    <div className="row mb-5">
                        <div className="col-lg-7 col-md-12 mb-5 mb-lg-0">
                            <div className="mb-7 mb-lg-9 d-flex justify-content-between align-items-end">
                                <a href="/">
                                    <img src="/images/logo-sm.png" alt="Kudzu Software" className="logo-sm" />
                                </a>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-4 mb-5 mb-md-0">
                                    <h5 className="mb-2">Overview</h5>                               
                                    <a href="/product/desktop" className="d-block mb-2 mb-lg-3">Kudzu Desktop</a>
                                    <a href="/product/generative-ai" className="d-block mb-2 mb-lg-3">Form Pilot</a>
                                    <a href="/product/uniform" className="d-block mb-2 mb-lg-3">Uniform</a>
                                    <a href="/product/connector-framework" className="d-block mb-2 mb-lg-3">Connector Framework</a>
                                    <a href="/product/recognition-services" className="d-block mb-2 mb-lg-3">Recognition Services</a>                                    
                                    <h5 className="mb-2 mt-5">Solutions</h5>
                                    <a href="/solution/infopath-replacement" className="d-block mb-2 mb-lg-3">InfoPath Replacement</a>
                                    <a href="/solution/infopath-consultancy" className="d-block mb-2 mb-lg-3">InfoPath Consultancy</a>
                                    <a href="/solution/accessibility" className="d-block mb-2 mb-lg-3">Accessibility</a>
                                </div>
                                <div className="col-md-4 mb-5 mb-md-0">
                                    <h5 className="mb-2">Connectors</h5>
                                    {connectors?.map(function (connector, index) {
                                        return <a href={`/connectors/${connector?.id}`} className="d-block mb-2 mb-lg-3" key={index.toString()}>{connector?.name}</a>
                                    })}
                                </div>
                                <div className="col-md-4">
                                    <h5 className="mb-2">Resources</h5>
                                    <a href="/blog/" className="d-block mb-2 mb-lg-3">Blog</a>
                                    <a href="/video-gallery" className="d-block mb-2 mb-lg-3">Videos</a>
                                    <a href="https://formulator.kudzusoftware.com" target="_blank" className="d-block mb-2 mb-lg-3">the FORMulator</a>
                                    {/*<a href="#" className="d-block mb-2 mb-lg-3">News and Articles</a>*/}
                                    {/*<a href="#" className="d-block mb-2 mb-lg-3">Events</a>*/}
                                    <h5 className="mb-2 mt-5">About</h5>
                                    <a href="/about/team" className="d-block mb-2 mb-lg-3">Team</a>
                                    {/*<a href="/about/history/" className="d-block mb-2 mb-lg-3">History</a>*/}
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-5 ms-auto">
                            <div className="py-5 bg-gray-800 px-4 rounded-4">
                                <h5 className="mb-4">Contact</h5>
                                <div><a href="mailto:sales@kudzusoftware.com"
                                    className="fs-5 link-hover-underline">sales @kudzusoftware.com</a>
                                </div>
                                <hr className="my-4 my-sm-5" />
                                    <h5 className="mb-4">Follow us</h5>
                                    <div className="mb-4 mb-md-0 d-flex">
                                        <a href="https://twitter.com/KudzuSoftware" target="_blank" className="d-inline-block mb-1 me-2 si rounded-pill si-hover-twitter">
                                            <FontAwesomeIcon icon={faTwitter} size="2x" color="white" />
                                        </a>
                                    <a href="https://www.linkedin.com/company/kudzusoftware" target="_blank" className="d-inline-block mb-1 me-2 si rounded-pill si-hover-linkedin">
                                        <FontAwesomeIcon icon={faLinkedin} size="2x" color="white" />
                                        </a>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-md-7 mb-4 mb-md-0">
                            <div className="d-flex small">
                                <a href="/privacy-policy" className="d-block me-3">Privacy Policy</a>
                                <a href="/terms-of-use" className="d-block me-3">Terms of Use</a>
                            </div>
                        </div>

                        <div className="col-md-5 text-md-end">
                            <span className="d-block lh-sm small">&copy; Copyright
                                <script>
                                    document.write(new Date().getFullYear())

                                </script>. Kudzu Software, Inc.
                            </span>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
