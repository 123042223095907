import * as React from 'react';
import { connect } from 'react-redux';
import { SuperSEO } from 'react-super-seo';
import ContactUsFooter from '../ContactUsFooter'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectCoverflow, Autoplay, Navigation, Pagination, EffectFade } from 'swiper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

const InfoPathReplacement = () => (
    <div>
        <SuperSEO
            title="Automated InfoPath Replacement - Kudzu"
            lang="en"
            googleSiteVerification="1hS9bBPIOWWfEIJ33ELv_Z75P9JK2Idlcc-TFCB8_lU"
            openGraph={{
                ogImage: {
                    ogImage: `https://${location.host}/images/kudzu-hero.png`,
                    ogImageAlt: 'Automated InfoPath Replacement - Kudzu',
                    ogImageType: 'image/png'
                },
                ogType: 'website',
                ogLocale: 'en-US',
                ogTitle: 'Automated InfoPath Replacement - Kudzu',
                ogSiteName: 'Kudzu',
                ogUrl: location.href
            }}
            twitter={{
                twitterSummaryCardWithLargeImage: {
                    summaryCardTitle: 'InfoPath Replacement - Kudzu',
                    summaryCardImage: `https://${location.host}/images/kudzu-hero.png`,
                    summaryCardImageAlt: 'InfoPath Replacement - Kudzu',
                    summaryCardSiteUsername: 'kudzusoftware'
                }
            }}
        />
        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-6 offset-1 z-index-1">
                        <h1 className="display-3 mb-4">
                            <span>Automated InfoPath Replacement</span>
                        </h1>
                        <div className="position-relative mb-5">
                            <div className="lead">
                                Kudzu's automation-assisted conversion tool can quickly replace your existing InfoPath forms with a growing number of low and no code technologies like Microsoft Power Apps, Nintex Automation Cloud, Jigx, and many more.  Most forms are converted in under a minute with just a few clicks.
                            </div>                            
                            <div className="row mt-5">
                                <div className="col-12 col-lg-4">
                                    <div className="card shadow-sm">
                                        <div className="card-header bg-secondary text-light text-center">
                                            ALL-IN-ONE SOLUTION
                                        </div>
                                        <div className="card-body">
                                            <ul className="fa-ul">
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" listItem />Discovery &amp; Analysis Tools</li>
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" listItem />Form Template Conversion</li>
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" listItem />Data Entity Creation</li>
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" listItem />Data Conversion &amp; Migration</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="card shadow-sm">
                                        <div className="card-header bg-secondary text-light text-center">
                                            AUTOMATED
                                        </div>
                                        <div className="card-body">
                                            <ul className="fa-ul">
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success" listItem />No Code to Write</li>
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success" listItem />No Scripts to Execute</li>
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success" listItem />Let Kudzu do ALL the Work!</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="card shadow-sm">
                                        <div className="card-header bg-secondary text-light text-center">
                                            FREE TOOLS
                                        </div>
                                        <div className="card-body">
                                            <ul className="fa-ul">
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" listItem />Discover Deployed Forms</li>
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" listItem />Analyze Form Complexity and Portability</li>
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" listItem />Detailed Reporting</li>
                                                <li><FontAwesomeIcon icon={faCheck} className="text-success me-2" listItem />Estimate Effort and Savings</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-4">
                        <div className="position-relative">
                            <img src="images/infopath-replacement-hero.png" alt="" className="w-100 ms-5" />
                        </div>
                    </div>
                    <div className="col-12 text-center">
                        <div className="position-relative">
                            {/*<a href="https://outlook.office365.com/owa/calendar/KudzuPlatformIntroduction@kudzusoftware.com/bookings/" className="btn btn-lg btn-primary">Book a FREE Consultation with one of our InfoPath Replacement Experts</a>*/}
                        </div>
                    </div>                    
                </div>
            </div>
        </section>

        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center mt-5">
                    <div className="col-12 col-sm-12 col-lg-6">
                        <div className="position-relative">
                            <Swiper spaceBetween={50} autoplay={{ delay: 4000 }} modules={[Autoplay, EffectFade]} effect="fade" speed={750}>
                                <SwiperSlide>
                                    <img src="images/formulator/scan.png" alt="" className="w-100" />
                                    <div className="slide-title">Point the FORMulator at your on-prem or SharePoint online instances</div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src="images/formulator/report.png" alt="" className="w-100" />
                                    <div className="slide-title">Review the scan results</div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src="images/formulator/roi.png" alt="" className="w-100" />
                                    <div className="slide-title">Use the ROI calculator to estimate conversion effort with and without Kudzu</div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src="images/formulator/share.png" alt="" className="w-100" />
                                    <div className="slide-title">Export the summary, details, and ROI calculator to Excel</div>
                                </SwiperSlide>
                            </Swiper>     
                            {/*<img src="images/connectors/infopath-formulator.png" alt="" className="w-100 shadow" style={{ border: '1px solid black' }} />*/}
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-5 mb-4">
                            <span>Step 1: Discover, Analyze, and Report</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <span>The Kudzu <b>FORMulator</b> will find all your published InfoPath forms, analyze their complexity and portability, and provide tools to plan and estimate conversion efforts with and without Kudzu.</span>
                            <p>The FORMulator's ROI calculator will also provide estimated Kudzu pricing.</p>
                            <br />
                            <a href="https://formulator.kudzusoftware.com/#download" target="_blank" className="btn btn-primary mt-3 me-3">DOWNLOAD YOUR FREE COPY TODAY!</a>
                            <a href="https://formulator.kudzusoftware.com" target="_blank" className="btn btn-outline-primary mt-3">LEARN MORE</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-4 offset-1 z-index-1">
                        <h1 className="display-5 mb-4">
                            <span>Step 2: Convert the Forms</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <span>Kudzu's patented form conversion platform transforms your InfoPath forms into our Uniform model. Once there it can be converted to leading low and no code platforms from Microsoft, Nintex, Jigx, and Formstack. Usually in a matter of seconds.</span>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                        <div className="position-relative">
                            <img src="images/infopath-replacement.png" alt="" className="w-100" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center mt-5">
                    <div className="col-12 col-sm-12 col-lg-6">
                        <div className="position-relative">
                            <img src="images/container-scaffolding.png" alt="" className="w-100" />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-5 mb-4">
                            <span>Step 3: Create Data Containers</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <span>Kudzu creates new containers, including child containers for repeating sections/tables, for the converted form's data. InfoPath Form Libraries can become SharePoint Lists, Dataverse Tables, K2 SmartObjects, and more.</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-lg-4 offset-1 z-index-1">
                        <h1 className="display-5 mb-4">
                            <span>Step 4: Migrate the Data</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <span>Kudzu has you covered here too! Kudzu's powerful data conversion and migration engine supports all native data types (including attachments) and advanced scenarios involving incremental migrations and promotion of source created and modified metadata. <br /><br /><a href="https://youtu.be/ThawtcVbVIw" target="_blank" className="btn btn-primary mt-3">SEE IT IN ACTION</a></span>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                        <div className="position-relative">
                            <img src="images/data-migration.png" alt="" className="w-100 shadow" />
                        </div>
                    </div>
                    {/*<div className="col-12 text-center mt-5"><a href="https://outlook.office365.com/owa/calendar/KudzuPlatformIntroduction@kudzusoftware.com/bookings/" className="btn btn-lg btn-primary">Book a FREE Consultation with one of our InfoPath Replacement Experts</a></div>*/}
                </div>
            </div>
        </section>

        <section className="position-relative bg-gradient-blur mb-5 pt-2 px-5">
            <div className="container-fluid px-xl-9 position-relative py-9 py-lg-11">
                <div className="row my-5">
                    <div className="col-lg-8 mx-auto text-center col-xl-7">
                        <h2 className="mb-6 mb-lg-9 display-5" data-aos="fade-down" data-aos-delay="100">
                            Unmatched Capabilities
                        </h2>
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                        <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                            {/*<FontAwesomeIcon icon={faFileImport} size="8x" className="mb-4 me-4 feature-icon" />*/}
                            <div className="feature-details">
                                <h5>Looking good</h5>
                                <p className="mb-0">
                                    Kudzu's InfoPath connector performs a deep analysis and high fidelity extraction of each form element's type, layout, style, relationships, rules, and more.  The result is a Uniform model that is highly representative of the original in both form and function.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                        <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                            {/*<FontAwesomeIcon icon={faEye} size="8x" className="mb-4 me-4 feature-icon" />*/}
                            <div className="feature-details">
                                <h5>Don't move!</h5>
                                <p className="mb-0">
                                    InfoPath forms deployed to SharePoint lists can be converted in-place without requiring data conversion and migration.  
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                        <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                            {/*<FontAwesomeIcon icon={faCopy} size="8x" className="mb-4 me-4 feature-icon" />*/}
                            <div className="feature-details">
                                <h5>Your move</h5>
                                <p className="mb-0">
                                    Convert your InfoPath forms to fully-native Power Apps, K2 SmartForms, the Nintex Automation Cloud, mobile experiences with Jigx, and more. You decide!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                        <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                            {/*<FontAwesomeIcon icon={faDatabase} size="8x" className="mb-4 me-4 feature-icon" />*/}
                            <div className="feature-details">
                                <h5>No data left behind</h5>
                                <p className="mb-0">
                                    For InfoPath forms that cannot be converted in-place Kudzu supports conversion and migration of InfoPath XML payloads (including attachments) to SharePoint lists, Dataverse tables, and K2 SmartObjects.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                        <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                            {/*<FontAwesomeIcon icon={faPenToSquare} size="8x" className="mb-4 me-4 feature-icon" />*/}
                            <div className="feature-details">
                                <h5>Repeat after me</h5>
                                <p className="mb-0">
                                    Kudzu's InfoPath connector detects repeating sections and tables, recording them in the Uniform model it generates. When it comes to authoring/rendering the Kudzu Power Apps, K2 SmartForm, and Uniform runtime all support repeaters.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4" data-aos="flip-right" data-aos-delay="100">
                        <div className="d-flex flex-column flex-md-row p-4 bg-white shadow rounded-3 hover-lift hover-shadow-lg">
                            {/*<FontAwesomeIcon icon={faFont} size="8x" className="mb-4 me-4 feature-icon" />*/}
                            <div className="feature-details">
                                <h5>Those that forget the past...</h5>
                                <p className="mb-0">
                                    Maybe you won't like some of your forms as Power Apps or K2 SmartForms. Maybe you'll want to move some to a brand new platform a few years down the road. Read your InfoPath forms into Uniform today and future-proof those efforts.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="position-relative bg-gradient-blur">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 p-5">
                <div className="row align-items-center mt-5">
                    <div className="col-12 col-sm-12 col-lg-6">
                        <div className="position-relative">
                            <img src="images/reimagine-kudzu-bot.png" alt="" className="w-100" />
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-5 me-xl-auto z-index-1 offset-lg-1">
                        <h1 className="display-5 mb-4">
                            <span>Reimagine Outdated InfoPath Form Designs...and Enhance them with Kudzu Bot</span>
                        </h1>
                        <div className="position-relative lead mb-5">
                            <span>The Kudzu Reimaginer can strip away decades of design debt, allowing the user to build a form model that conforms to modern design and accessibility standards. And then leverage Kudzu bot to create a richer user experience by converting free text inputs to choice lists and assisting you in adding new fields based on what's already being captured or based on additional context provided by the user (e.g., a corporate website URL).<br /><br /><p>That's right, Kudzu Bot can personalize a form with information it captures from referenced web properties!</p></span>
                            <a href="https://youtu.be/j5RwuFZP3U8" target="_blank" className="btn btn-lg btn-primary">See it in action</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="position-relative mb-5 bg-gradient-blur pt-3">
            <div className="container-fluid px-xl-9 position-relative z-index-1">
                <div className="row mb-5">
                    <div className="col-lg-8 mx-auto text-center col-xl-7">
                        <h2 className="display-4">
                            See it in action
                        </h2>
                    </div>
                </div>
                <div className="container-fluid px-xl-9 position-relative z-index-1 my-5">
                    <div className="row align-items-center col-12 col-lg-6 offset-lg-3">
                        <Swiper spaceBetween={100} modules={[Navigation, Pagination, EffectCoverflow]} effect="coverflow" speed={750} navigation={true} className="px-5"
                            onSlideChange={(swiperCore) => {
                                var player = swiperCore.slides[swiperCore.previousIndex].querySelector('iframe');
                                player?.contentWindow?.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                            }}>
                            <SwiperSlide>
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/eZ47gDE-shg?enablejsapi=1" title="YouTube video player"
                                        frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/QL3XI61aWvM?enablejsapi=1" title="YouTube video player"
                                        frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/S2XDVNjqEzI?enablejsapi=1" title="YouTube video player"
                                        frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/QPcnbpEhLo4?enablejsapi=1" title="YouTube video player"
                                        frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ThawtcVbVIw?enablejsapi=1" title="YouTube video player"
                                        frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
                {/*<div className="col-12 text-center"><a href="https://outlook.office365.com/owa/calendar/KudzuPlatformIntroduction@kudzusoftware.com/bookings/" className="btn btn-lg btn-primary">Book a FREE Consultation with one of our InfoPath Replacement Experts</a></div>*/}
            </div>
        </section>
        <ContactUsFooter />
    </div>
);

export default connect()(InfoPathReplacement);
