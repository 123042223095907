import * as React from 'react';
import { connect } from 'react-redux';
import './ContactUs.css';
import { Spinner } from 'reactstrap';

interface FormElements extends HTMLFormControlsCollection {
    firstName: HTMLInputElement,
    lastName: HTMLInputElement,
    email: HTMLInputElement,
    phone: HTMLInputElement,
    company: HTMLInputElement,
    title: HTMLInputElement,
    hearAboutUs: HTMLSelectElement,
    inquiryType: HTMLSelectElement,
    message: HTMLTextAreaElement,
    contactUsSubmit: HTMLButtonElement,
}

interface ContactFormElement extends HTMLFormElement {
    readonly elements: FormElements
}

const handleSubmit = (e: React.FormEvent<ContactFormElement>) => {
    e.persist();
    e.preventDefault();

    var submitButton = e.currentTarget.elements.contactUsSubmit;
    submitButton.disabled = true;

    var submitLabel = document.getElementById('contactUsSubmitLabel');
    submitLabel!.innerText = 'Submitting';
    var submitSpinner = document.getElementById('contactUsSubmitSpinner');
    submitSpinner!.style.display = '';

    var submissionMessage = document.getElementById('submissionMessage') ?? new HTMLSpanElement();

    var model = {
        FirstName: e.currentTarget.elements.firstName.value,
        LastName: e.currentTarget.elements.lastName.value,
        Email: e.currentTarget.elements.email.value,
        Phone: e.currentTarget.elements.phone.value,
        Company: e.currentTarget.elements.company.value,
        Title: e.currentTarget.elements.title.value,
        HearAboutUs: e.currentTarget.elements.hearAboutUs.value,
        InquiryType: e.currentTarget.elements.inquiryType.value,
        Message: e.currentTarget.elements.message.value
    };

    fetch(`${window.location.protocol}//${window.location.host}/api/forms/contact`, {
        headers: {
            'Accept': "application/json, text/plain, */*",
            'Content-Type': "application/json;charset=utf-8"
        },
        method: "POST",
        body: JSON.stringify(model)
    }).then(response => {
        if (!response.ok)
            return Promise.reject();

        submissionMessage.classList.remove('text-danger');
        if (!submissionMessage.classList.contains('text-success'))
            submissionMessage.classList.add('text-success');       
        submissionMessage.innerText = "Your request was submitted!";
        submitButton.disabled = false;

        submitLabel!.innerText = 'Submit';
        submitSpinner!.style.display = 'none';

    }).catch(error => {
        submissionMessage.classList.remove('text-success');
        if (!submissionMessage.classList.contains('text-danger'))
            submissionMessage.classList.add('text-danger');
        submissionMessage.innerText = `Your request could not be submitted. Please try again!`;
        submitButton.disabled = false;

        submitLabel!.innerText = 'Submit';
        submitSpinner!.style.display = 'none';

    });
}

const ContactUs = () => {

    return (
        <section className="position-relative bg-gradient-blur mb-5 pt-5">
            <div className="container-fluid px-xl-9 position-relative pt-lg-14 pt-11 pb-12 z-index-1 px-5">
                <div className="row align-items-center">
                    <div className="col-10 offset-1">
                        <h1 className="display-3 mb-4">
                            <span>Contact Us</span>
                        </h1>
                    </div>
                </div>
                <div className="row align-items-center mt-2">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        <div className="position-relative lead display-6 mb-3">
                            <p>Let us know how we can help...</p>
                        </div>
                    </div>
                </div>
                <form method="post" id="contactUsForm" onSubmit={handleSubmit}>
                    <div className="row align-items-center mt-2">
                        <div className="col-12 col-lg-4 offset-lg-2">
                            <div className="mb-3">
                                <label htmlFor="firstName" className="form-label contact-label">First Name</label>
                                <input type="text" className="form-control contact-control" id="firstName" placeholder="John" required />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="mb-3">
                                <label htmlFor="lastName" className="form-label contact-label">Last Name</label>
                                <input type="text" className="form-control contact-control" id="lastName" placeholder="Smith" required />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-2">
                        <div className="col-12 col-lg-4 offset-lg-2">
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label contact-label">Business Email</label>
                                <input type="email" className="form-control contact-control" id="email" placeholder="john@acme.com" required />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="mb-3">
                                <label htmlFor="phone" className="form-label contact-label">Phone</label>
                                <input type="tel" className="form-control contact-control" id="phone" placeholder="+1 (201)555-1212" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-2">
                        <div className="col-12 col-lg-4 offset-lg-2">
                            <div className="mb-3">
                                <label htmlFor="company" className="form-label contact-label">Company Name</label>
                                <input type="text" className="form-control contact-control" id="company" placeholder="Acme Inc." required />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label contact-label">Title</label>
                                <input type="text" className="form-control contact-control" id="title" placeholder="Manager, Modern Workplace" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-2">
                        <div className="col-12 col-lg-4 offset-lg-2">
                            <div className="mb-3">
                                <label htmlFor="hearAboutUs" className="form-label contact-label">How did you hear about us?</label>
                                <select className="form-control contact-control" id="hearAboutUs">
                                    <option value="">(please choose)</option>
                                    <option value="Google Search">Google Search</option>
                                    <option value="Google Ad">Google Ad</option>
                                    <option value="LinkedIn">LinkedIn</option>
                                    <option value="Twitter">Twitter</option>
                                    <option value="Event">Event/Sponsorship</option>
                                    <option value="Email/Newsletter">Email/Newsletter</option>
                                    <option value="Referral">Partner Referral</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="mb-3">
                                <label htmlFor="inquiryType" className="form-label contact-label">What can we help you with?</label>
                                <select className="form-control contact-control" id="inquiryType">
                                    <option value="">(please choose)</option>
                                    <option value="Schedule a Demo">Schedule a Demo</option>
                                    <option value="Request a Quote">Request a Quote</option>
                                    <option value="Support Question">Support Question</option>
                                    <option value="General Question">General Question</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-2">
                        <div className="col-12 col-lg-8 offset-lg-2">
                            <div className="mb-3">
                                <label htmlFor="message" className="form-label contact-label">Message</label>
                                <textarea className="form-control contact-control" id="message" rows={3}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-2">
                        <div className="col-12 col-lg-8 offset-lg-2 text-center">
                            <button type="submit" id="contactUsSubmit" className="btn btn-primary" style={{ width: "200px" }}>
                                <span id="contactUsSubmitLabel">Submit</span>
                                <Spinner id="contactUsSubmitSpinner"
                                    style={{ width: "0.7rem", height: "0.7rem", marginLeft: "1rem", display: "none" }}
                                    type="grow"
                                    color="light"
                                />
                            </button>
                            <span id="submissionMessage" className="ms-2"></span>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default connect()(ContactUs);
