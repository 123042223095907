import * as React from 'react';
import { connect } from 'react-redux';

const TermsOfUse = () => {

    return (
        <section className="position-relative mb-5 pt-5">
            <div className="container-fluid position-relative z-index-1 p-5">
                <div className="mx-5" id="legal-list">
                    <h1 className="display-3 mb-4">
                        <span>Terms of Use</span>
                    </h1>
                    <p><b>Last Updated: April 5, 2022</b></p>
                    <p>
                        PLEASE READ THESE TERMS OF USE ("TERMS") CAREFULLY. The website www.kudzusoftware.com ("the Site") is owned and operated by Kudzu Software, Inc.  ("Company," "we," "our," or "us"). The Site provides information regarding our proprietary software which may be used to convert, create, manage and/or automate a customer's business form documents (the "Services").
                    </p>
                    <p>THESE TERMS SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SERVICE. BY SIGNING UP FOR AN ACCOUNT, SUBMITTING A REQUEST FOR INFORMATION, AND/OR BROWSING THE SITE, YOU ("YOU") ARE ACCEPTING THESE TERMS, AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS. IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, YOU DO NOT HAVE THE RIGHT OR AUTHORITY TO USE THE SERVICE.</p>
                    <p>PLEASE NOTE THAT THE TERMS ARE SUBJECT TO CHANGE BY COMPANY IN ITS SOLE DISCRETION AT ANY TIME. When changes are made, Company will make a new copy of the Terms available at [INSERT].  We will also update the "Last Updated" date at the top of the Terms. If we make any material changes, and You have provided Your e-mail address to us, we will also send an e-mail to You pursuant to the Terms. Any changes to the Terms will be effective immediately for new users of the Service and will be effective thirty (30) days after posting notice of such changes on the Site for existing users, provided that any material changes shall be effective for users for whom we have an e-mail address upon the earlier of thirty (30) days after posting notice of such changes on the Site or thirty (30) days after dispatch of an e-mail notice of such changes to existing users. Company may require You to provide consent to the updated Terms in a specified manner before further use of the Service is permitted. If You do not agree to any change(s) after receiving a notice of such change(s), You shall stop using the Site and/or the Service.  Otherwise, Your continued use of the Site and/or Services constitutes Your acceptance of such change(s). PLEASE REGULARLY CHECK THE SITE TO VIEW THE THEN-CURRENT TERMS.</p>
                    <h6>ACCESS TO THE SERVICE</h6>
                    <ol>
                        <li><b>License.</b>  Subject to these Terms, Company grants You a non-transferable, non-exclusive, revocable, limited license to access the Site and to use the other aspects of the Service made available to You, in each case solely for Your own personal or internal business purposes.</li>
                        <li><b>Certain Restrictions.</b>  The rights granted to You in these Terms are subject to the following restrictions: (a) You shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Service, whether in whole or in part, or any content displayed on the Service; (b) You shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Service; (c) You shall not access the Service in order to build a similar or competitive website, product, or service; and (d) except as expressly stated herein, no part of the Service may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means. Unless otherwise indicated, any future release, update, or other addition to functionality of the Service shall be subject to these Terms. All copyright and other proprietary notices on the Service (or on any content displayed on the Service) must be retained on all copies thereof.</li>
                        <li><b>Modification.</b>  Company reserves the right, at any time, to modify, suspend, or discontinue the Service (in whole or in part) with or without notice to You. You agree that Company will not be liable to You or to any third party for any modification, suspension, or discontinuation of the Service or any part thereof.</li>
                        <li><b>No Support or Maintenance.</b>  You acknowledge and agree that Company will have no obligation to provide You with any support or maintenance in connection with the Service.</li>
                        <li><b>Ownership.</b>  Excluding any Customer Information that You may provide (defined in Section 3.1 below), You acknowledge that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Service and its content are owned by Company or Company's suppliers. Neither these Terms (nor Your access to the Service) transfers to You or any third party any rights, title, or interest in or to such intellectual property rights, except for the limited access rights expressly set forth in Section 1.1. Company and its suppliers reserve all rights not granted in these Terms. There are no implied licenses granted under these Terms.</li>
                    </ol>
                    <h6>PRIVACY. Our Privacy Policy is located here: [INSERT]. We provide our Privacy Policy to give you information to learn about:</h6>
                    <ul>
                        <li>What information we may collect about You;</li>
                        <li>What we use that information for; and</li>
                        <li>With whom we share that information.</li>
                    </ul>
                    <h6>CUSTOMER INFORMATION</h6>
                    <ol>
                        <li><b>"Customer Information"</b> means any and all information that You submit through the Service in connection with Your use of the Services. You are solely responsible for Your Customer Information. You assume all risks associated with use of Your Customer Information, including any reliance on its accuracy, completeness or usefulness by others, or any disclosure of Your Customer Information that personally identifies You. You represent and warrant that: (a) all Customer Information You submit is truthful and accurate and (b) You will maintain the accuracy of such Customer Information.</li>
                        <li><b>License; Authorization.</b>  You hereby grant (and you represent and warrant that you have the right to grant) to Company an irrevocable, nonexclusive, royalty-free, and fully paid, worldwide license to reproduce, distribute, publicly display, and perform, prepare derivative works of, incorporate into other works, and otherwise use Your Customer Information, and to grant sublicenses of the foregoing rights, solely for the purposes of providing the Service.</li>
                        <li><b>Feedback.</b> You hereby grant to Company a royalty-free, worldwide, transferable, sublicensable, irrevocable, perpetual license to use or incorporate into the Service any suggestions, enhancement requests, recommendations, or other feedback You provide relating to the Service ("Feedback"). Company will treat any Feedback You provide to Company as non-confidential and non-proprietary. You agree that You will not submit to Company any information or ideas that You consider to be confidential or proprietary.</li>
                    </ol>
                    <h6>FEES. <div>There is no charge for Your access to and use of the Site or Service. However, Company reserves the right to charge fees for Your access to and use of all or portions of the Service.  You will be provided notice if Company begins to charge fees for your access and use of the Site or Services.</div></h6>
                    <h6>INDEMNIFICATION. <div>You agree to indemnify and hold Company (and its officers, employees, and agents) harmless, including costs and attorneys' fees, from any claim or demand made by any third party due to or arising out of (a) Your Customer Information, (b) Your violation of these Terms; or (c) Your violation of applicable laws, rules, or regulations. Company reserves the right, at Your expense, to assume the exclusive defense and control of any matter for which You are required to indemnify us, and You agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of Company. Company will use reasonable efforts to notify You of any such claim, action or proceeding upon becoming aware of it.</div></h6>                    
                    <h6>DISCLAIMERS</h6>
                    <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE IS PROVIDED ON AN "AS-IS" AND "AS AVAILABLE" BASIS, AND COMPANY (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT: (A) THE SITE OR SERVICE WILL MEET YOUR REQUIREMENTS OR (B) YOUR USE OF THE SITE OR SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE. </p>
                    <p>TO THE FULLEST EXTENT ALLOWED BY LAW, COMPANY DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE POSTED MATERIAL ON THE SITE OR SERVICES IN TERMS OF ITS CORRECTNESS, ACCURACY, TIMELINESS, RELIABILITY OR OTHERWISE.</p>
                    <p>BY PROVIDING THE SERVICES ON THE SITE, COMPANY DOES NOT IN ANY WAY PROMISE THAT THE SITE OR SERVICES WILL REMAIN AVAILABLE TO YOU. COMPANY IS ENTITLED TO TERMINATE ALL OR PART OF ANY OF THE SITE OR SERVICES AT ANY TIME, IN ITS SOLE DISCRETION WITHOUT NOTICE TO YOU.</p>
                    <h6>LIMITATION OF LIABILITY</h6>
                    <p>YOU UNDERSTAND AND AGREE THAT IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY LOSS OF PROFITS, REVENUE OR DATA, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE SERVICE, OR DAMAGES OR COSTS DUE TO PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, WHETHER OR NOT COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THE TERMS OR THE SERVICE, ON ANY THEORY OF LIABILITY, WHETHER BASED ON WARRANTY, COPYRIGHT, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY.</p>
                    <p>OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO THE GREATER OF (A) FIFTY US DOLLARS (U.S. $50), AND (B) THE AMOUNT PAID BY YOU TO COMPANY IN THE SIX (6) MONTHS IMMEDIATELY PRIOR TO THE DATE OF THE EVENT GIVING RISE TO SUCH ASSERTED CLAIM.</p>
                    <p>THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN COMPANY AND YOU.</p>
                    <h6>TERM AND TERMINATION. <div>Subject to this Section, these Terms will remain in full force and effect while You use the Service. We may suspend or terminate Your rights to use the Service at any time for any reason at our sole discretion, including for any use of the Service in violation of these Terms. If You want to terminate the Service provided by Company, You may do so by (a) notifying Company at any time and (b) ceasing any access to or use of the Service. Your notice should be sent, in writing (e-mail is sufficient), to Company's contact set forth below. Upon termination of Your rights under these Terms, Your right to access and use the Service will terminate immediately. You understand that any termination may involve deletion of Your Customer Information from our databases. Company will not have any liability whatsoever to You for any termination of Your rights under these Terms, including for deletion of Your Customer Information. Even after Your rights under these Terms are terminated, the following provisions of these Terms will remain in effect: Sections 1.2, 1.5, 6-8 and 11.</div></h6>
                    <h6>THIRD-PARTY LINKS. <div>The Site may contain links to other third-party websites. Such third-party websites are maintained by persons or organizations over which Company exercises no control. Your use of these third-party websites is governed by the terms of use and privacy policy of such websites.  Company expressly disclaims any responsibility for the content or results from your use of such third-party websites.</div></h6>
                    <h6>COPYRIGHT COMPLAINTS</h6>
                    <p>Company respects the intellectual property of others. If you believe that your work has been copied in a way that constitutes copyright infringement, please provide Company's copyright agent with the following information.</p>
                    <ul>
                        <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;</li>
                        <li>Description of the copyrighted work that you claim has been infringed;</li>
                        <li>The location on the Site of the material that you claim is infringing;</li>
                        <li>Your address, telephone number and e-mail address;</li>
                        <li>A statement that your claim of infringement is based on a good faith belief; and</li>
                        <li>A statement made under penalty of perjury that the information you have provided is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
                    </ul>
                    <p>Company's agent for notice of claims of copyright infringement is noted below.</p>
                    <h6>GENERAL</h6>
                    <ol>
                        <li><b>Governing Law.</b>  The Terms and any action related thereto will be governed and interpreted by and under the laws of the State of Georgia, without giving effect to any principles that provide for the application of the law of another jurisdiction. The United Nations Convention on Contracts for the International Sale of Goods does not apply to these Terms. You agree that all claims and disputes arising out of or relating to the Agreement will be litigated exclusively in the state or federal courts located in Atlanta, Georgia.</li>
                        <li><b>Notice.</b>  Where Company requires that You provide an e-mail address, You are responsible for providing Company with Your most current e-mail address. If the last e-mail address You provided to Company is not valid, or for any reason is not capable of delivering to You any notices required/ permitted by the Terms, Company's dispatch of the e-mail containing such notice will nonetheless constitute effective notice. You may give notice to Company at the address below. Such notice shall be deemed given when received by Company by letter delivered by nationally recognized overnight delivery service or first-class postage prepaid mail at the above address</li>
                        <li><b>Force Majeure.</b>  Company shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including, but not limited to, acts of God, war, terrorism, riots, embargos, acts of civil or military authorities, fire, pandemics, government shutdowns, floods, accidents, strikes or shortages of transportation facilities, fuel, energy, labor, or materials.</li>
                        <li><b>Entire Agreement.</b> These Terms constitute the entire agreement between You and the Company regarding the use of the Service. Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. The section titles in these Terms are for convenience only and have no legal or contractual effect. If any provision of these Terms is, for any reason, held to be invalid or unenforceable, the other provisions of these Terms will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law. These Terms create no relationship of employee-employer, agency, partnership, or joint venture between the parties. These Terms, and Your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by You without Company's prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. Company may freely assign these Terms. The terms and conditions set forth in these Terms shall be binding upon assignees.</li>
                        <li><b>Consumer Complaints.</b>  In accordance with California Civil Code &sect;1789.3, California residents may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.</li>
                        <li><b>Questions, Complaints, Claims.</b>  If You have any questions, complaints or claims with respect to Service, please contact us at the contact information below. We will do our best to address Your concerns. If You feel that Your concerns have been addressed incompletely, we invite You to let us know for further investigation.</li>
                        <li><b>Copyright/Trademark Information.</b>  Copyright 2022 Kudzu Software, Inc., All rights reserved. All trademarks, logos, and service marks ("Marks") displayed on the Service are Company's property or the property of other third parties. You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks.</li>
                        <li>Contact Information:
                            <p>
                                Kudzu Software, Inc.<br />
                                Attn: Ron Douglass<br />
                                Email: sales@kudzusoftware.com<br />
                                4555 Mansell Road, Suite 300<br />
                                Alpharetta, GA 30022
                            </p>
                        </li>
                    </ol>
                </div>
            </div>
        </section>
    );
};

export default connect()(TermsOfUse);

